@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html,
body {
	overflow-x: hidden;
}

@media screen and (max-width: 3841px) and (min-width : 3830px) {
	/* body {
    transform: scale(2);
    transform-origin: top;
	} */
}

@media screen and (max-width: 3741px) and (min-width : 3730px) {
	/* body {
    transform: scale(1.8);
    transform-origin: top;
	} */
}

@media screen and (max-width: 3641px) and (min-width : 3630px) {
	/* body {
    transform: scale(1.6);
    transform-origin: top;
	} */
}

@media screen and (max-width: 3541px) and (min-width : 3530px) {
	/* body {
    transform: scale(1.4);
    transform-origin: top;
	} */
}

@media screen and (max-width: 3361px) and (min-width : 3350px) {
	/* body {
    transform: scale(1.6);
    transform-origin: top;
	} */
}

@media screen and (max-width: 3259px) and (min-width : 3250px) {
	/* body {
		transform: scale(1.5);
		transform-origin: top center;
	} */
}

/* @media screen and (max-width: 2561px) and (min-width : 2550px) {
	body {
    transform: scale(1.2);
    transform-origin: top;
	}
} */

@media screen and (max-width: 1920px) and (min-width : 1920px) {
	/* body {
    transform: scale(1);
    transform-origin: top;
	} */
}

body {
	margin: 0;
	padding: 0 !important;
	background-color: #fff;
	overflow-x: hidden;
	/* padding-top: 61px !important; */
}

* {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	position: relative;
	word-wrap: break-word;
}

::marker {
	font-weight: 800;
}

dl,
ol,
ul {
	margin-bottom: 0;
}

a,
p,
button,
svg,
span,
input[type="button"],
input[type="submit"],
.card {
	text-decoration: none !important;
	-webkit-transition: all 0.5s ease !important;
	-moz-transition: all 0.5s ease !important;
	transition: all 0.5s ease !important;
}



p,
li,
button,
input {
	color: #fff;
	margin: 0;
	font: 400 16px/26px 'Poppins', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	font-family: 'Montserrat', sans-serif;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
	color: #fff;
	margin: 0;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	font-weight: 400;
}

.text-wrapper p:not(:last-child) {
	margin-bottom: 25px;
}

ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

h1,
.h1 {
	font-size: 75px;
	line-height: 1.1;
}

h2,
.h2 {
	font-size: 40px;
	line-height: 1.2;
}

h3,
.h3 {
	font-size: 30px;
	line-height: 1.2;
}

h4,
.h4 {
	font-size: 25px;
	line-height: 1.2;
}

h5,
.h5 {
	font-size: 24px;
	line-height: 1.2;
}

h6,
.md-txt {
	font-size: 16px;
	line-height: 1.2;
}

.fs-18 {
	font-size: 18px;
	line-height: 1.4;
}

.fs-15 {
	font-size: 15px;
	line-height: 1.4;
}

.fs-14 {
	font-size: 14px;
	line-height: 1.4;
}

.fs-12 {
	font-size: 12px;
	line-height: 1.4;
}

.f-900 {
	font-weight: 900;
}

.f-800 {
	font-weight: 800;
}

.f-700 {
	font-weight: 700;
}

.f-600 {
	font-weight: 600;
}

.f-500 {
	font-weight: 500;
}

.f-400 {
	font-weight: 400;
}

.f-300 {
	font-weight: 300;
}

.f-200 {
	font-weight: 200;
}

.f-100 {
	font-weight: 100;
}

.f-italic {
	font-style: italic;
}

.f-uppercase {
	text-transform: uppercase;
}

.fb {
	color: #000 !important;
}

.container-fluid {
	max-width: 1920px;
}

::before,
::after {
	content: "";
	display: none;
}

.img-wrapper img{
	width: 100%;
	height: 100%;
	object-fit: contain;
}

@media screen and (min-width: 1501px) {
	.custom-wrapper {
		max-width: 1550px;
		width: 100%;
		margin: 0 auto;
		/* z-index: 1; */
		z-index: auto;
	}
}

.custom-wrapper {
	margin: 0 auto;
}

.flex-elem {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-pack: center;
	justify-content: center;
	-ms-flex-align: center;
	align-items: center;
}

/* cockie styles*/
.wrap {
	position: fixed;
	z-index: 2000;
	right: 18px;
	bottom: 18px;
	max-width: 220px;
	background: rgba(63, 61, 57, .9);
	padding: 18px;
}

.wrappara {
	font-size: 11px;
	line-height: 20px;
	color: #fff;
}

.wrap .my_btn {
	width: 100%;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	padding: 11px;
	font: 700 16px/16px 'Poppins';
	color: #fff;
	text-transform: uppercase;
	letter-spacing: 1px;
	border: 1px solid #B381C9;
	border-radius: 5px;
}

.wrap .my_btn:hover {
	background: #B381C9;
	color: #fff !important;
}

.wrap a {
	font-size: 14px;
}

/* end cockie styles*/

/* Global Upgrade Button Start */
.content-wrapper {
	width: 100%;
	margin: 0 auto;
}

.img-fluid {
	display: block;
}

/* Global Upgrade Button End */