.show-drop {
    display: block !important;
    z-index: 9 !important;
}

.dropdown-menu {
    right: -50px;
    left: auto !important;
    margin-top: 15px;
}

.cursor-pointer {
    cursor: pointer;
}

.active-article {
    background: #22459F;
    ;
}

.support-data {
    color: #ffffff;
}

.support-link {
    color: #ffffff;
}



/*color picker*/
.swatch {
    background: #fff;
    border-radius: 1px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, .1);
    display: inline-block;
    cursor: pointer;
    width: 100%;
    padding: 10px;
}

.swatch-color {
    width: 100%;
    height: 14px;
    border-radius: 2px;
}

.swatch-cover {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0
}

.sketch-picker {
    position: absolute;
    z-index: 999;
}

/* end Color picker */

.wrap-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .4);
    border-radius: 10px;
    cursor: not-allowed;
}


.active-audio .active-audio-wrapper i {
    color: #00f5a0;
}

.active-audio .active-audio-wrapper {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    background: #000000b3;
    z-index: 1;
    border-radius: 7px;
}

.active-audio-wrapper {
    display: none;
}

.sweet-alert h2 {
    color: #212529;
}

/*loader css*/
.loader::after {
    width: 10px;
    height: 10px;
    background-color: #38405b;
    border-radius: 100%;
    position: absolute;
    content: "";
}

.loader {
    width: 80px;
    height: 80px;
    border: 2px #38405b solid;
    border-radius: 50%;
    animation: rotation 1s ease-in-out infinite;
    text-align: center;
}

@keyframes rotation {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loader-sec {
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}


.icon-response {
    width: 100%;
    ;
    display: flex;
    justify-content: center;
}

.icon-response img {
    width: 80px;
}

span.icon-response-span .wrap-icon-image {
    border: 1px solid #1e409c;
    background: #1e409c;
    border-radius: 5px;
    padding: 8px;
}

span.icon-response-span .wrap-icon-image:hover {
    border: 1px solid #1e409c;
    background: #1e409c;
    background: linear-gradient(to right, #0d235c 0%, #092b5e 100%);
    box-shadow: 0px 0px 10px 2px #0053b2, 0 0 1px 0 #0437ab;
    border-radius: 5px;
    padding: 8px;
}

.active-push {
    border: 1px solid #1e409c;
    background: #1e409c;
    background: linear-gradient(to right, #0d235c 0%, #092b5e 100%);
    box-shadow: 0px 0px 10px 2px #0053b2, 0 0 1px 0 #0437ab;
}

.render-status {
    text-align: center;
    width: 100%;
    height: 100%;
    padding: 20px;
    background: #004a9e;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.render-status p {
    padding-bottom: 15px;
}

.progress-wrap {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 15px;
}

.bg-ul {
    height: 50px !important;
    width: 100% !important;
    background-repeat: repeat-x !important;
    background-position: center;
}

/* Define an animation behavior */
@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}

/* This is the class name given by the Font Awesome component when icon contains 'spinner' */
.fa-spinner {
    /* Apply 'spinner' keyframes looping once every second (1s)  */
    font-size: inherit;
    animation: spinner 2s linear infinite;
}

.info-form .form-control::-webkit-input-placeholder {
    /* Edge */
    color: #fff;
}

.info-form .form-control:-ms-input-placeholder {
    /* Internet Explorer */
    color: #fff;
}

.info-form .form-control::placeholder {
    color: #fffd;
}

.overlay__media__wrapper {
    position: absolute;
    content: "";
    /* background-color: rgba(0, 0, 0, 0.9); */
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: all 0.2s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

.overlay__media__wrapper button {
    background: #5A68C1;
    padding: 4px 20px;
    font-size: 14px;
    border-radius: 100px;
    color: #fff !important;
    border: none;
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s ease;
    transform: translateY(10px);
    margin: 0 5px;
}

.overlay__media__wrapper:hover button {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
}

.metaverse-video-box:hover button {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
}

.metaverse-video-box-alt {
    margin-bottom: 8px !important;
}

.metaverse-video-box-alt+p {
    padding-bottom: 20px !important;
}

.overlay__media__wrapper svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    width: 35px !important;
    height: 35px;
    display: inline-block;
    background: #5a68c1;
    padding: 8px;
    border-radius: 50px;
}

.lMore {
    text-align: center;
    padding-top: 35px;
}

.lMore button {
    background: #5A68C1;
    padding: 10px 30px;
    border-radius: 100px;
    color: #fff !important;
    border: none;
}

.play-push {
    color: #00ffba;
    font-size: 25px;
}

.play-push a {
    cursor: pointer;
}

.modal-content canvas {
    width: 100% !important;
    height: 400px !important;
}

.player-popup div {
    width: 100% !important;
}

.active-slide {
    border: 1px solid #00ffba !important;
}

.opacity-0 {
    opacity: 0;
}

h6.tabDesc {
    font-size: 19px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    padding: 0 0 15px 0;
}

.tab-content .modal {
    margin-top: 70px;
}

.preview-text {
    position: absolute;
    z-index: 2;
    width: 100%;
}

.preview-text span {
    display: block;
    padding: 0 50px;
    text-align: center;
}

.preview-text span span {
    /* display: inline !important;  */
    padding: 15px 15px;
    /* -webkit-box-decoration-break: clone; 
    box-decoration-break: clone; */
}

.text-preview-wrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.text-preview-wrapper-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.box-slide-cover {
    width: 100%;
    height: 100%;
}

.show-drop label {
    display: block;
    color: #FFF;
    margin: 0;
    padding: 0;
}

.show-drop input[type="range"] {
    width: 100% !important;
}

.volDiv {
    padding-left: 30px;
}

.publish-icon.icon img {
    width: 100%;
}

.pnlm-controls-container {
    display: none !important;
}

.preview-loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #0c5460;
    color: #FFFFFF;
    z-index: 2;
}

.preview-loader-text {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;

}

.disable-img img {
    filter: grayscale(100%);
}

.active-help {
    color: #00F5A0 !important;
}

.support-link:hover {
    color: #00F5A0 !important;
}


.homeModal {
    padding-top: 0 !important;
}

.demo-modal .modal-content {
    background: none !important;
}

.footer .fs-18 {
    font-size: 16px;
}

.footer .footer-menu ul li a:hover {
    color: blue;
}

p.copyTxt a {
    color: #00FFFF !important;
}

.footer li a:hover {
    color: #00d47f !important;
}

.footer-copyright a {
    color: #00F5A0;
}

.integration-box li:hover {
    background: linear-gradient(180deg, #00D3EB, #16B891);
    box-shadow: 0 0 20px 0 #00D3EB, 0 0 20px 0 #16B891;
    transform: scale(1.1) !important;
}

.user-img .custom-file {
    z-index: 999;
}

.imgEdit {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 100%;
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s ease;
}

.user-img:hover .imgEdit {
    opacity: 1;
    visibility: visible;
}

.imgsizeright {
    margin-left: -230px;
}

.commonModal.demo-modal .modal-content {
    background: none !important;
}

.help-support-banner {
    margin-top: 0;
}

.profile-sec {
    padding-top: 60px;
}

.gdpr-sec {
    padding-top: 1px;
}

.footer .footer-menu ul li a:hover {
    color: blue;
}

p.copyTxt a {
    color: #00FFFF !important;
}

.footer li a:hover {
    color: blue !important;
}

.footer-copyright a {
    color: #00F5A0;
}

.integration-box li:hover {
    background: linear-gradient(180deg, #00D3EB, #16B891);
    box-shadow: 0 0 20px 0 #00D3EB, 0 0 20px 0 #16B891;
    transform: scale(1.1) !important;
}

.user-img .custom-file {
    z-index: 999;
}

.imgEdit {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 100%;
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s ease;
}

.user-img:hover .imgEdit {
    opacity: 1;
    visibility: visible;
}

.imgsizeright {
    margin-left: -230px;
}


.training-main-body .vid-content {
    min-height: calc(100vh - 100px);
}

.training-main-body .sidebar {
    min-height: calc(100vh - 100px);
}

.training .vid-content .colmns img {
    width: auto !important;
    max-width: 100% !important;
}


.commonModal .modal-content {
    background: none !important;
    border: none !important;
}

.commonModal .modal-body {
    background: #000;
    overflow: hidden;
    border-radius: 20px;
    padding: 25px;
}

.commonModal .embed-responsive {
    width: 100%;
    height: 0;
    padding-bottom: 56%;
    position: relative;
    aspect-ratio: 16 / 9;
}

.commonModal .embed-responsive iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    display: block;
    top: 0;
    left: 0;
}

.commonModal .modal-dialog {
    max-width: 1130px !important;
}

.commonModal .close {
    position: absolute;
    right: 0;
    top: -42px;
    opacity: 1 !important;
}

.modal {
    display: block !important;
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s ease;
}

.modal.show-drop {
    opacity: 1;
    visibility: visible;
}

.commonModal .modal-dialog {
    transform: scale(0);
    transition: all 0.5s ease;
}

.commonModal.show-drop .modal-dialog {
    transform: scale(1);
}

.logoimg {
    margin-top: -60px;
}

.upgrads-sec {
    padding-top: 50px;
    min-height: calc(100vh - 110px);
}

.upgrade-content {
    min-height: 10px;
}

.project-banner {
    padding-top: 50px;
}

.dashb-sec h4 {
    font-size: 18px;
}

.dashb-sec h3 {
    color: #00FFFF;
}

/* .dashb-sec p{ font-size: 18px; line-height: 26px;} */

.update-body-page .tab-content {
    min-height: calc(100vh - 200px);
}

.container {
    max-width: 1500px !important;
}

@media screen and (max-height: 800px) {
    .commonModal .modal-dialog {
        max-width: 940px !important;
    }
}


@media (max-width: 1599px) {
    .commonModal .modal-dialog {
        max-width: 850px !important;
    }

    .container {
        max-width: 1200px !important;
    }
}


@media (max-width: 1699px) {
    .imgsizeleft {
        margin-left: 0;
    }

    .imgsizeleft img {
        max-width: 100%;
    }

    .imgsizeright {
        margin-left: 0;
    }

    .imgsizeright img {
        max-width: 100%;
    }

    .banner h1 {
        font-size: 60px;
    }

    /* .banner h4{ font-size: 30px;} */
    .tutorial-page-main .banner .custom-content h2 {
        font-size: 40px;
    }

    .training .vid-content .colmns {
        flex: 0 0 100% !important;
        max-width: 100% !important;
    }
}

@media (max-width: 1199px) {
    .banner h1 {
        font-size: 45px;
    }

    .banner h4 {
        font-size: 25px;
    }

    .banner p {
        font-size: 15px;
    }

    .banner-video-project .video-project h5 {
        font-size: 35px;
    }

    .dashborad-sec-3-content h4 {
        font-size: 25px;
    }

    .dashborad-sec-3-content h6 {
        font-size: 16px;
    }

    .dashborad-sec-3-content p {
        font-size: 15px;
    }

    .webiner-content p {
        font-size: 15px;
    }

    .webiner-content h3 {
        font-size: 25px;
    }

    .footer .footer-copyright p {
        font-size: 13px;
    }

    .footer .footer-menu ul li a {
        font-size: 13px;
    }

    .integration-sec {
        padding-top: 60px;
    }

    .help-banner-content h3 {
        font-size: 25px;
    }

    .secarchicone-part #searchimg {
        left: 0;
    }

    .popular-topics-box .nav .nav-link {
        padding-left: 20px;
    }

    .dashboard-page-main .banner .content-wrapper {
        max-width: 90% !important;
    }

    .topLogo {
        width: 245px !important;
    }

    .dashboard-page-main .banner .in-wrap-head {
        width: 100%;
    }

    .dashb-sec h3 {
        font-size: 24px;
    }

    /* .dashb-sec p{ font-size: 15px; line-height: 24px;} */
    .dashb-sec h4 {
        font-size: 15px;
    }

    .integration-page-body .banner .content-wrapper {
        max-width: 100% !important;
    }

    .integration-page-main .banner .custom-wrapper {
        max-width: 90% !important;
    }

    .integration-page-main .banner .integrations .content .col {
        flex: 0 0 20% !important;
    }

    .integration-page-main .banner .connections {
        max-width: 100% !important;
    }

    .training .vid-content {
        padding: 30px !important;
    }

    .training .vid-content .scrollable-content {
        padding-right: 0 !important;
    }

    .update .tab-pane {
        margin: 0 !important;
        padding: 0 !important;
    }

    .tab-desc {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .acadeable-tutorials .box-wrapper {
        flex: 0 0 50% !important;
        max-width: 50% !important;
        margin-bottom: 20px;
    }

    .update .box-wrapper {
        padding: 0px 15px !important;
    }
}

@media (max-width: 1023px) {
    .footer-copyright {
        padding: 8px 0;
    }

    .navbar-collapse {
        position: fixed;
        left: 0;
        top: 71px;
        width: 100%;
        height: 100%;
        background: #000;
        padding: 30px;
        padding-top: 0;
    }

    header .navbar ul li {
        margin-right: 0;
    }

    header .navbar .navbar-nav .nav-link {
        padding-top: 15px;
        padding-bottom: 15px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    }

    header .dropdown button {
        padding: 15px 0;
    }

    header .dropdown-menu {
        position: static !important;
        width: 100%;
    }

    .traning-sec .col-md-4,
    .traning-sec .col-md-8 {
        width: 100%;
    }

    .popular-topics-content {
        min-height: 10px;
        margin-top: 30px;
    }

    .training-main-body .sidebar {
        min-height: 10px;
    }

    .training-main-body .vid-content {
        min-height: 10px;
    }

    .account-page-body .banner p {
        text-align: left !important;
    }
}

@media (max-width: 767px) {

    #profile-sec {
        padding: 50px 0 !important;
    }

    .banner h2 {
        padding-top: 50px;
    }

    .banner h1 {
        font-size: 35px;
    }

    .banner h4 {
        font-size: 20px;
    }

    .banner-items {
        padding: 0 0 30px 0 !important;
    }

    .banner {
        padding: 20px 0 !important;
        text-align: center !important;
    }

    .help-support-banner {
        padding-top: 60px;
    }

    .dashboard-page-main .banner .colmn-2 .in-wrap {
        margin: 0 !important;
        margin-top: 12px !important;
    }

    .topLogo {
        width: 200px !important;
    }

    .dashboard-page-main .banner .fs-18 {
        font-size: 12px !important;
    }

    .s3 .content-wrapper {
        padding-top: 40px !important;
        padding-bottom: 40px !important;
    }

    .dashb-sec h3 {
        font-size: 20px;
    }

    .dashb-sec h4 {
        font-size: 13px;
    }

    /* .dashb-sec p{ font-size: 12px; line-height: 18px;} */

    .integration-page-main .banner .integrations .content .col {
        flex: 0 0 100% !important;
        padding: 0 !important;
        margin: 0 !important;
        margin-top: 15px !important;
    }

    .table-wrapper tr td,
    .table-wrapper tr th {
        white-space: nowrap !important;
    }

    .create-acc-sec .form-wrapper {
        padding: 0 !important;
    }

    .privacy-account {
        padding: 40px 0 0 0 !important;
    }

    .update-body-page .update .colmn-2 {
        margin: 0 0 !important;
        padding: 0 30px !important;
    }

    .update-body-page .tab-content {
        min-height: 10px !important;
    }

    .acadeable-tutorials .box-wrapper {
        margin: 0 !important;
        margin-bottom: 15px !important;
        padding: 0 !important;
        flex: 0 0 100% !important;
        max-width: 100% !important;
    }

    #list-license .box-wrapper {
        margin: 0 !important;
        margin-bottom: 15px !important;
        padding: 0 !important;
        flex: 0 0 100% !important;
        max-width: 100% !important;
    }

    #list-website .box-wrapper {
        display: block !important;
        padding: 0 !important;
    }

    #list-website .tab-pane .img-wrapper {
        width: 100% !important;
        margin-left: 0 !important;
        text-align: center !important;
        flex: 0 0 100% !important;
        max-width: 100% !important;
    }

    #list-website .img-wrapper img.img-fluid {
        display: inline-block !important;
    }

    .list-website .box-wrapper .img-wrapper {
        flex: 0 0 100% !important;
        max-width: 100% !important;
    }

    .project-count {
        font-size: 18px;
    }

    .update .tab-pane .img-wrapper {
        margin-left: inherit !important;
        text-align: center !important;
    }

    .update .colmn-1 {
        padding: 15px !important;
    }

    .tab-desc {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    .update .colmn-1 {
        padding-left: 30px !important;
        padding-right: 30px !important;
    }

    /* .update .box-wrapper{ padding: 0 !important;} */
}