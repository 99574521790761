.login-page-body {
  height: 100vh;
}

.login-page-main {
  height: 100%;
  overflow: hidden;
}

.login-page-main .banner {
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
  height: 100% !important;
  padding: 50px 0;
}

.login-page-main .banner .row {
  max-width: 1480px;
}

.login-page-main .banner .title-area {
  /* padding-left: 30px; */
}

.dash-logo img{ max-width: 300px;}
.dash-logo{ padding-bottom: 15px;}

/* .login-page-main .banner .title-area:before {
  display: block;
  height: 95%;
  width: 9px;
  position: absolute;
  left: 0;
  border-radius: 10px;
  background: rgb(0,255,153);
  background: -moz-linear-gradient(top,  rgba(0,255,153,1) 0%, rgba(255,255,255,1) 100%);
  background: -webkit-linear-gradient(top,  rgba(0,255,153,1) 0%,rgba(255,255,255,1) 100%);
  background: linear-gradient(to bottom,  rgba(0,255,153,1) 0%,rgba(255,255,255,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ff99', endColorstr='#ffffff',GradientType=0 );
} */

.login-page-main .banner .form-group .d-flex {
  background: #fff;
  padding: 10px 30px;
  border-radius: 10px;
  overflow: hidden;
}

.login-page-main .banner .email-icon {
  margin-right: 18px;
}

.login-page-main .banner input:focus {
  outline: none;
  color: #00d47f;
}

.login-page-main .banner input::placeholder {
  color: #A4A4A4;
}

.login-page-main .banner input {
  border: none;
  border-left: 1px solid #A4A4A4;
  padding: 0 18px;
  width: 100%;
  color: #A4A4A4;
}

.login-page-main .banner .form-group .d-flex:before {
  display: block;
  position: absolute;
  left: 0;
  width: 10px;
  height: 100%;
  background: #00D47F;
}

.login-page-main .banner .colmn-2 {
  background: rgba(0 0 0 / 70%);
  padding: 30px;
  border-radius: 23px;
  border: 3px solid #00FFFF;
  box-shadow: 0px 0px 38px rgb(0 0 0 / 50%);
}

.login-page-main .banner form button {
  background: rgb(54,255,228);
  background: -moz-linear-gradient(left,  rgba(54,255,228,1) 0%, rgba(119,0,255,1) 50%, rgba(54,255,228,1) 100%);
  background: -webkit-linear-gradient(left,  rgba(54,255,228,1) 0%,rgba(119,0,255,1) 50%,rgba(54,255,228,1) 100%);
  background: linear-gradient(to right,  rgba(54,255,228,1) 0%,rgba(119,0,255,1) 50%,rgba(54,255,228,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#36ffe4', endColorstr='#36ffe4',GradientType=1 );
  background-size: 200% auto;
  border: none;
  padding: 15px 0;
  width: 100%;
  border-radius: 100px;
}

.login-page-main .banner form button:hover {
  background-position: right;
}

.login-page-main .banner .form-group {
  margin-bottom: 30px;
}

.login-page-main .banner .colmn-2 {
  max-width: 46%;
}

.login-page-main .banner .colmn-1 {
  max-width: 43%;
}

.login-page-main .banner a {
  color: #00D47F;
}

.login-page-main .banner a:hover {
  color: #00FFFF!important;
}

.login-page-main .banner .form-group label {
  margin-bottom: 15px;
}

@media screen and (max-width: 1680.98px) {

}

@media screen and (max-width: 1500.98px) {
  .login-page-main .banner .custom-wrapper{
    max-width: 1250px;
  }
}

@media screen and (max-width: 1440.98px) {

}

@media screen and (max-width: 1366.98px) {
  .login-page-main .banner .row{
    max-width: 100%;
    margin: 0 auto;
  }
}

@media screen and (max-width: 1280.98px) {
  h1, .h1 {
    font-size: 70px;
  }

  .login-page-main .banner .custom-wrapper {
    max-width: 90%;
  }
}

@media screen and (max-width: 1024.98px) {
  /* .login-page-body {
    height: unset;
  } */

  .login-page-main .banner {
    padding: 70px 0 50px;
  }

  .login-page-main .banner .custom-wrapper {
    max-width: 70%;
    transform: translateY(-60px);
  }

  .login-page-main .banner .colmn-1{
    max-width: 98%;
    flex: 0 0 100%;
    margin-bottom: 30px;
  }

  .login-page-main .banner .colmn-2{
    max-width: 98%;
    flex: 0 0 100%;
  }

  .login-page-main .banner .justify-content-between {
    justify-content: center !important;
  }

  .login-page-body .footer {
    background: rgba(0 0 0 / 100%);
  }
}

@media screen and (max-width: 991.98px) {
  .login-page-main .banner {
    padding: 100px 0 50px;
  }
}

@media screen and (max-width: 832.98px) {

}

@media screen and (max-width: 767.98px) {
  h3, .h3 {
    font-size: 27px;
  }

  p, .md-txt{
    font-size: 18px;
  }

  .login-page-main .banner .title-area::before{
    display: none;
  }

  .login-page-main .banner .title-area, .login-page-main .banner .text-wrapper{
    padding: 0;
    text-align: center;
  }

  .login-page-body{ height: auto !important;}
  .loGin h1{ font-size: 40px;}
  .login-page-body .footer{ position: static;}
  .footer [class*='col-']{ -ms-flex: 0 0 100%; flex: 0 0 100%; max-width: 100%;}
  .footer .list.d-flex{ justify-content: center; align-items: center; padding-top: 15px; padding-bottom: 15px;}
  .footer .fs-18.text-center{ padding-bottom: 15px;}
  .login-page-main .banner{ padding-bottom: 0; padding-top: 100px !important;}
}

@media screen and (max-width: 640.98px) {
  h1, .h1 {
    font-size: 55px;
  }

  h2, .h2 {
    font-size: 35px;
  }

  .login-page-main .banner .custom-wrapper {
    max-width: 90%;
  }

  .login-page-main .banner .colmn h3{
    text-align: center;
  }
}

@media screen and (max-width: 575.98px) {

}

@media screen and (max-width: 440.98px) {
  h1, .h1 {
    font-size: 45px;
  }

  h2, .h2 {
    font-size: 28px;
  }

  h3, .h3 {
    font-size: 20px;
  }

  p, .md-txt{
    font-size: 16px;
  }

  .login-page-main .banner .custom-wrapper{
    max-width: 95%;
  }

  .login-page-main .banner .colmn-2{
    padding: 25px;
  }
}

@media screen and (max-width: 360.98px) {
  .fs-15 {
    font-size: 12px;
  }

  .login-page-main .banner .custom-wrapper {
    max-width: 100%;
  }

  .login-page-main .banner .form-group .d-flex {
    padding: 14px 20px;
  }

  .login-page-main .banner .email-icon {
    margin-right: 12px;
  }

  .login-page-main .banner input {
    font-size: 12px;
    color: #00D47F;
  }
}


/* @media (min-width: 2550px) {
  .login-page-body { height: calc(100vh - 126px);}
} */