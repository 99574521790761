.meta-lib-page-body, .meta-lib-page-main {
  min-height: 100vh;
  background: #000E34;
  display: flex;
  flex-direction: column;
}

.meta-lib-page-body video {
  display: block;
}

header {
  position: fixed;
  z-index: 9;
  width: 100%;
  left: 0;
  top: 0;
}

header + section, header + div{ margin-top: 62px;}
.editor-main header + section{ margin-top: 0; padding-top: 0;}
.editor-main header{ left: -10px; position: static !important;}

.videos-wrapper {
  width: 100%;
  flex: 1 1 auto;
  display: flex;
  /* align-items: center; */
  padding: 20px 0;
}

.videos-wrapper-alt{ 
  /* align-items: center; */
  padding-top: 50px;
}

.scriptTitle{ display: flex; align-items: center; justify-content: space-between;}
.translateDiv{ display: flex; align-items: center; font-family: 'Montserrat', sans-serif !important;}
.translateDiv select{ width: 200px; border: none; padding: 8px 4px; margin-left: 10px;}
.translateDiv select:focus{ border: none !important; box-shadow: none !important; outline: none !important;}
.translateDiv select:focus-visible{ border: none !important; box-shadow: none !important; outline: none !important;}

.translatTitle{ color: #FFF; font-size: 18px;}

.videos-wrapper .custom-wrapper {
  max-width: 80%;
}

.video-menu-tabs a.md-txt {
  border-radius: 0;
  border: none;
  height: 100%;
  text-align: center;
  padding: 28px 0;
  background: #8490BB;
  color: #fff;
}

.video-menu-tabs a.md-txt.active {
  background: #5A68C1;
}

.video-menu-tabs .list-group {
  flex-direction: unset;
  border-radius: 15px;
  overflow: hidden;
}

.search-wrapper {
  justify-content: space-between;
  max-width: 1070px;
  background: #fff;
  padding: 13px 16px 13px 40px;
  border-radius: 100px;
  margin: 40px auto 70px;
}

.search-wrapper.alt{ background: none; border-radius: 0; margin: 0 auto; padding: 30px 0 0 0;}
.search-wrapper.alt .alert{ padding: 0 0 0 43px !important; font-style: italic; font-size: 13px;}
.search-wrapper.alt + .search-wrapper{ margin-top: 7px;}

.search-wrapper .search-box {
  width: 90%;
  border: none;
  background: transparent;
  margin: 0 15px;
  outline: none;
  color: #B1B1B1;
  font-size: 18px;
}

.search-wrapper .search-box::placeholder {
  color: #B1B1B1;
}

.search-wrapper .img-wrapper {
  flex: 0 0 22px;
  max-width: 22px;
}

.search-wrapper .search-btn {
  background: #5A68C1;
  padding: 10px 30px;
  border-radius: 100px;
  color: #fff !important;
}

.video-tab-content {
  /* height: 50vh; */
  /* overflow-y: auto !important; */
  overflow-x: hidden;
  /* padding-right: 30px; */
}

#videos-wrapper .custom-wrapper {
  height: auto;
  /* height: 700px; */
  /* background: red; */
  overflow: hidden;
}

.video-tab-content::-webkit-scrollbar {
  width: 7px;
}

.video-tab-content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 25px #fff; 
  border-radius: 10px;
}

.video-tab-content::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 50px #5A68C1; 
}

.videos-wrapper .gallery .colmn {
  flex: 0 0 25%;
  max-width: 22%;
}

.videos-wrapper .gallery .colmn:not(:nth-child(4n + 4)) {
  margin-right: auto;
}

.videos-wrapper .gallery .colmn:not(:nth-last-child(-n + 4)) {
  margin-bottom: 40px;
}

.videos-wrapper .gallery .colmn {
  flex: 0 0 25%;
  max-width: 23%;
}

.videos-wrapper .media-wrapper img,
.videos-wrapper .media-wrapper video {
  width: 100%;
  overflow: hidden;
  object-fit: cover;
  object-position: center;
}

.meta-lib-page-body footer {
  background: #00113E;
  z-index: 9;
  position: unset;
}

@media screen and (max-width: 1599px) {
  .media-wrapper.test3 img{ height: 135px;}
}

@media screen and (max-width: 1366.98px) {
  .video-menu-tabs a.md-txt {
    padding: 15px 0;
  }
}

@media screen and (max-width: 1024.98px) {
  .search-wrapper .search-box {
    font-size: 14px;
  }

  .search-wrapper {
    padding: 5px 5px 5px 30px;
    margin: 20px auto 30px;
  }

  .videos-wrapper .gallery .colmn {
    flex: 0 0 33%;
    max-width: 31%;
  }

  .videos-wrapper .gallery .colmn:not(:nth-child(4n + 4)) {
    margin-right: unset;
  }

  .videos-wrapper .gallery .colmn:not(:nth-child(3n + 3)) {
    margin-right: auto;
  }

  .videos-wrapper .gallery .colmn:not(:nth-last-child(-n + 4)) {
    margin-bottom: unset;
  }

  .videos-wrapper .gallery .colmn:not(:nth-last-child(-n + 3)) {
    margin-bottom: 25px;
  }
}

@media screen and (max-width: 991.98px) {
  .videos-wrapper {
    padding: 60px 0;
  }
}

@media screen and (max-width: 767.98px) {
  .videos-wrapper .gallery .colmn {
    flex: 0 0 50%;
    max-width: 48%;
  }

  .videos-wrapper .gallery .colmn:not(:nth-child(3n + 3)) {
    margin-right: unset;
  }

  .videos-wrapper .gallery .colmn:not(:nth-child(2n + 2)) {
    margin-right: auto;
  }

  .videos-wrapper .gallery .colmn:not(:nth-last-child(-n + 3)) {
    margin-bottom: unset;
  }

  .videos-wrapper .gallery .colmn:not(:nth-last-child(-n + 2)) {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 640.98px) {
  .videos-wrapper .custom-wrapper {
    max-width: 95%;
  }

  .video-menu-tabs a.md-txt {
    font-size: 16px;
  }
}

@media screen and (max-width: 575.98px) {
  .video-tab-content {
    padding-right: 10px;
  }
}

@media screen and (max-width: 440.98px) {
  .videos-wrapper {
    padding: 20px 0;
  }

  .videos-wrapper .fs-18 {
    font-size: 12px;
  }

  .search-wrapper {
    padding: 5px 5px 5px 15px;
  }

  .videos-wrapper .custom-wrapper {
    max-width: 100%;
  }

  .videos-wrapper .gallery .colmn {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .videos-wrapper .gallery .colmn:not(:nth-child(2n + 2)) {
    margin-right: unset;
  }

  .videos-wrapper .gallery .colmn:not(:nth-last-child(-n + 2)) {
    margin-bottom: unset;
  }

  .videos-wrapper .gallery .colmn:not(:nth-last-child(-n + 1)) {
    margin-bottom: 20px;
  }

  .video-menu-tabs a.md-txt {
    font-size: 12px;
  }

  .video-menu-tabs .list-group {
    border-radius: 5px;
  }

  .search-wrapper .search-box {
    font-size: 12px;
  }
}