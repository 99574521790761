.account-page-main .footer {
  position: unset;
  background: #00113E;
} 

.account-page-body .banner{
  background: #000E34;
  /* background: -moz-linear-gradient(top,  #5372c4 0%, #001a5e 100%);
  background: -webkit-linear-gradient(top,  #5372c4 0%,#001a5e 100%); 
  background: linear-gradient(to bottom,  #5372c4 0%,#001a5e 100%); 
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5372c4', endColorstr='#001a5e',GradientType=0 ); */
  padding: 100px 0;
  color: #fff;
}

.account-page-body .banner .custom-wrapper{
  max-width: 1397px;
  margin: 0 auto;
}

/* .account-page-body .banner .profile{
  max-width: 220px;
  margin: 80px auto 52px;
} */

.user-profile .upload-file{
  cursor: pointer;
  position: relative;
}

.account-page-body .banner .profile label {
  padding: 12px 30px;
  /* cursor: pointer; */
  border-radius: 30px;
  background: #00cb7a; 
  background: -moz-linear-gradient(left,  #00cb7a 0%, #0091c8 50%, #00cb7a 99%); 
  background: -webkit-linear-gradient(left,  #00cb7a 0%,#0091c8 50%,#00cb7a 99%); 
  background: linear-gradient(to right,  #00cb7a 0%,#0091c8 50%,#00cb7a 99%); 
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00cb7a', endColorstr='#00cb7a',GradientType=1 );
  background-size: 200% auto;
  text-decoration: none !important;
  -webkit-transition: all 0.5s ease !important;
  -moz-transition: all 0.5s ease !important;
  transition: all 0.5s ease !important;
}
.account-page-body .banner .profile label:hover {
  background-position: right;
}

.account-page-body .banner .account-form {
  margin-left: 0;
  margin-right: 0;
  background: #002D5A;
  border-radius: 20px;
  border: 3px solid #00CBFF;
  padding: 20px 20px;
  box-shadow: 0 3px 22px rgba(0, 0, 0, 0.37);
}

.account-page-body .banner .account-form:first-child{
  margin-bottom: 50px;
}

.account-page-body .banner .account-form .title{
  margin-bottom: 50px;
}

.account-page-body .banner .row{
  margin-left: 0;
  margin-right: 0;
}

.account-page-body .banner .form-inputs {
  background: transparent;
  border: navajowhite;
  padding:0;
  color: #fff;
  font-size: 16px;
  border-radius: 10px;
}

.pass-form input::-webkit-input-placeholder { /* Edge */
  color: #fff;
}

.pass-form input:-ms-input-placeholder { /* Internet Explorer */
  color: #fff;
}

.pass-form input::placeholder {
  color: #fff;
}

.account-page-body .banner .info-form .col-12 {
  max-width: 49%;
  margin-bottom: 35px;
}

.account-page-body .banner .pass-form .col-lg-6, .banner .pass-form .col-12 {
  max-width: 49%;
  margin-bottom: 35px;
}

.account-page-body .banner form .col-12 {
  background: #4E83BB;
  padding: 10px 20px;
  border-radius: 8px;
  border: 1px solid #D9D9D9;
}

::placeholder{
  color: #fff;
  font-size: 16px;
}

/* .account-page-body .banner .account-form .mail{
  max-width: 100%;
} */

.account-page-body .banner .account-form .submit-form{
  padding: 23px 0;
  border-radius: 40px;
  border: none;
  background: #3686ff;
  background: -moz-linear-gradient(-45deg, #3686ff 0%, #c867ff 50%, #3686ff 99%);
  background: -webkit-linear-gradient(-45deg, #3686ff 0%,#c867ff 50%,#3686ff 99%);
  background: linear-gradient(135deg, #3686ff 0%,#c867ff 50%,#3686ff 99%);
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#3686ff', endColorstr='#3686ff',GradientType=1 );
  background-size: 200% auto;
}

.account-page-body .banner .account-form .submit-form:hover {
  background-position: right;
}

/* enable absolute positioning */
.account-page-body .banner .inner-addon {
    position: relative; 
}

/* style icon */
.account-page-body .banner .inner-addon .img-wrapper {
  position: absolute;
  padding: 10px;
  pointer-events: none;
}

/* align icon */
.account-page-body .banner .pass-form .right-addon .img-wrapper {
  right: 0px;
  z-index: 1;
  background: transparent;
  border: 0;
}
.profile.user-profile {
  padding: 50px 0;
  margin: 0 auto;
}


.profile .img-wrapper {
  /* width: 100%; */
  border-radius: 50px;
  width: 100px;
  height: 100px;
  margin: 0 auto;
  overflow: hidden;
}

/* add padding  */
.account-page-body .banner .pass-form .right-addon .form-inputs{
  padding-right: 30px; 
}

.user-img .my-account-loader{ position: absolute; left: 0; top: 0; z-index: 1; width: 100%; height: 100%; display: flex; align-items: center;justify-content: center;}
.authP{ white-space: nowrap; overflow: hidden; text-overflow: ellipsis; max-width: 87px;}

@media screen and (max-width: 1680.98px) { 

}

@media screen and (max-width: 1500.98px) {	

}

@media screen and (max-width: 1440.98px) {
  .account-page-body .banner .custom-wrapper {
    max-width: 90%;
  }
}

@media screen and (max-width: 1366.98px) {
  h3, .h3 {
    font-size: 26px;
  }

  h4, .h4 {
    font-size: 22px;
  }

  .account-page-body .banner .account-form{
    padding: 68px 70px;
  }

  .account-page-body .banner form .col-12 {
    padding: 10px 30px;
  }

  .account-page-body .banner .custom-wrapper {
    max-width: 80%;
  }

}

@media screen and (max-width: 1280.98px) {
  h1, .h1 {
		font-size: 70px;
	}

  h2, .h2 {
    font-size: 35px;
  }
}

@media screen and (max-width: 1199.98px) {
  h6, .md-txt {
    font-size: 18px;
  }

  .account-page-body .banner .account-form {
    padding: 60px 30px;
  }
  .account-page-body .banner .account-form .title {
    margin-bottom: 35px;
  }

}

@media screen and (max-width: 1024.98px) {
  
}

@media screen and (max-width: 991.98px) {
  .account-page-body .banner {
    padding: 50px 0 100px;
  }

  .account-page-body .banner .account-form .mail{
    margin: 0;
  }

  .account-page-body .banner .info-form .col-12 {
    margin-bottom: 15px;
  }

  .account-page-body .banner .pass-form .col-12 {
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 832.98px) {  
  .fs-18 {
    font-size: 12px;
  }

}

@media screen and (max-width: 767.98px) {
  h3, .h3 {
    font-size: 27px;
  }

  .md-txt{
    font-size: 18px;
  }
  .account-page-body .banner .account-form {
    padding: 60px 30px;
  }

  .account-page-body .banner .form-inputs {
    font-size: 14px;
  }
}

@media screen and (max-width: 640.98px) {
  h1, .h1 {
		font-size: 55px;
	}

	h2, .h2 {
		font-size: 28px;
	}

  h3, .h3 {
    font-size: 25px;
  }

  h4, .h4 {
    font-size: 16px;
  }

  .account-page-body .banner .custom-wrapper {
    max-width: 90%;
  }
}

@media screen and (max-width: 575.98px) {
  .account-page-body .banner .account-form .submit-form {
    padding: 15px 0;
  }
}

@media screen and (max-width: 440.98px) {
  .account-page-body .banner form .col-12 {
    max-width: 100%!important;
    flex: 0 0 100%!important;
  }

  .account-page-body .banner .account-form {
    padding: 40px 10px 30px;
  }

  .account-page-body .banner .profile {
    max-width: 100%;
    margin: 50px auto 20px;
  }

  .account-page-body .banner {
    padding: 50px 0 30px;
  }
}

@media screen and (max-width: 360.98px) { 
  .fs-15 {
    font-size: 12px;
  }

  h1, .h1 {
    font-size: 45px;
  }

  h3, .h3 {
    font-size: 20px;
  }

  .md-txt {
    font-size: 14px;
  }

  .account-page-body .banner .account-form {
    padding: 30px 0px;
  }

  .account-page-body .banner .account-form .title {
    margin-bottom: 20px;
  }
}