.update-body-page {
	background: #38405b;
}

/* .update-main-body {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	background: #38405b;
	min-height: 100vh;
}  */

section.update, .update .custom-wrap {
	flex: 1 1 auto;
	width: 100%;
	display: flex;
	flex-direction: column;
	height: 100%;
}

.update .sec-row {
	flex: 1 1 auto;
	/* width: 100%; */
}

.update-main-body label {
	font-family: 'Poppins', sans-serif;
}

/* .update-main-body .header {
	position: unset;
} */

.update-main-body .footer {
	position: unset;
	background: #00113E;
}

.update .custom-wrap{
	/* position: absolute; */
	width: 100%;
	height: 100%;
}

.update, .update .sec-row, .update .column {
	height: 100%;
	min-height: calc(100vh - 110px);
	/* width: 100%; */
}

.update .colmn-1{
	/* background: #081030; */
	padding: 42px 52px;
}

.update .colmn-2{
	/* background: #38405B; */
	padding: 30px 3% 30px;
}


.update .topic-sub a.active {
	background: #006180;
}

.update .topics{
	/*padding: 0 50px 37px;*/
	background: #28335A;
	border-radius: 20px;
	overflow: hidden;
}

.update .topic-sub a {
	padding: 20px 37px!important;
	cursor: pointer;
	color: #fff;
	background: none;
	border-radius: 0;
	border: none;
	padding: unset;
	display: block;
	font-family: 'Poppins', sans-serif;
}

.update .topic-sub a:hover{
	background: #006180;
}

.tab-header {
	background: #22459F;
	padding: 20px 40px;
}

.tab-desc {
	background: #28335A;
	padding: 20px 40px 30px;
}

.update .tab-pane {
	/* padding: 50px 65px; */
	background: #081030;
	border-radius: 22px;
	margin-top: 30px;
}

.tab-wrapper {
	border-radius: 10px;
	overflow: hidden;
	margin-bottom: 30px;
}

.list-rebranding .ar-upload-wrapper {
	position: relative;
	border: 2px solid #fff;
	padding: 30px 20px;
	border-style: dashed;
	background: #363839;
	margin-bottom: 50px;
	/* display: block; */
}

.list-rebranding .ar-upload-wrapper input {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	opacity: 0;
	cursor: pointer;
	display: block;
}

.ar-form-wrap input {
	border: none;
	width: 100%;
	padding: 5px 15px;
	border-radius: 2px;
	margin-bottom: 15px;
	font-size: 14px;
}

.ar-form-wrap button svg{
	position: relative;
	top: 5px;
	right: 5px;
}

.ar-form-wrap button {
	border: none;
	background: #006180;
	padding: 10px 30px;
	border-radius: 100px;
	margin: 0 auto;
	display: flex;
	cursor: pointer;
	/* position: relative; */
}

.ar-form-wrap {
	max-width: 450px;
	margin: 0 auto;
}

.update .box-wrapper {
	background: transparent;
	padding: 20px 30px;
	border-radius: 10px;
}

.update .box-group {
	background: transparent;
	padding: 20px 30px;
	border-radius: 10px;
}

.list-templates .box-wrapper {
	max-width: max-content;
	margin: 0 auto;
}

.update .sec-row {
    background: #000E34;
}

.update .list-client .box-wrapper{
	background-color: #171717;
}

.update .box-wrapper a {
	background: #006180;
	padding: 10px 20px;
	display: block;
	border-radius: 100px;
	text-align: center;
	max-width: max-content;
	margin: 15px auto 0;
}

.update  a.btn-green-blue {
	background: #006180;
	padding: 10px 20px;
	display: block;
	border-radius: 100px;
	text-align: center;
	max-width: max-content;
	margin: 15px auto 0;
}

.list-acadeable .box-group {
	max-width: 350px;
	margin: 0 auto;
}

.acadeable-tutorials {
	display: flex;
	flex-wrap: wrap;
}

.acadeable-tutorials .box-wrapper {
	flex: 0 0 49%;
	max-width: 48%;
	margin-bottom: 20px;
}

.acadeable-tutorials .box-wrapper a {
	margin-top: 0;
	max-width: 100%;
}

.list-client .box-wrapper a {
	margin: 0 auto;
	max-width: 100%;
}

.list-client .box-wrapper {
	border-radius: 0 0 10px 10px;
}

.list-client .col-header {
	text-align: center;
	background: #006180;
	padding: 10px;
	border-radius: 10px 10px 0 0;
}

.list-license .box-wrapper a {
	margin: 0;
	max-width: 100%;
}

.list-license .box-wrapper {
	flex: 0 0 49%;
	max-width: 49%;
}

.list-hosting .box-wrapper, 
.list-commercial .box-wrapper,
.list-dfy .box-wrapper {
	max-width: 300px;
	margin: 0 auto;
}

.list-hosting .box-wrapper a,
.list-commercial .box-wrapper a {
	max-width: 100%;
}

.list-website .box-wrapper {
	display: flex;
}

.list-website .box-wrapper .img-wrapper {
	flex: 0 0 100px;
	max-width: 100px;
}

.list-website .box-wrapper:not(:last-child) {
	margin-bottom: 20px;
}

.webite-tutorial .d-flex:not(:last-child) {
	margin-bottom: 20px;
}

.webite-tutorial a {
	background: #006180;
	padding: 10px 20px;
	border-radius: 100px;
	width: 100%;
	text-align: center;
	overflow: hidden;
	display: inline-block;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.webite-tutorial .web-tutorial-btn {
	flex: 0 0 80%;
	max-width: 80%;
}

.webite-tutorial .img-wrapper {
	flex: 0 0 20%;
	max-width: 100px;
}

.webite-tutorial {
	background: #081030;
}

.webite-tutorial .colmn {
	background: unset;
}

.youtubeicon {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	display: flex;
	align-items: center;
	justify-content: center;
}

#ytModal .modal-content {
	background: #333;
}


@media screen and (max-width: 1680.98px) {

}

@media screen and (max-width: 1500.98px) {	
	.update .colmn-1{ padding-right: 0;}

}

@media screen and (max-width: 1440.98px) {
	.update .fs-18 {
    font-size: 16px;
	}
}

@media screen and (max-width: 1366.98px) {
}

@media screen and (max-width: 1280.98px) {
	
}

@media screen and (max-width: 1024.98px) {
	.update .fs-18 {
    font-size: 14px;
	}

	.update .colmn-1 {
    padding: 30px 40px;
	padding-right: 0;
	}

	.update .topic-sub ul li {
    padding: 20px 20px;
	}

	.update .tab-pane {
    padding: 25px;
	padding-left: 0;
	}

	.update .tab-pane .img-wrapper {
    width: 40px;
    margin-left: auto;
	}
}

@media screen and (max-width: 991.98px) {
 .update-main-body {
    display: unset;
	}

	.update .colmn-2 {
    padding: 30px;
	padding-left: 15px;
	}

	.update .topic-sub a {
    padding: 18px 20px!important;
	}

	.update, .update .sec-row, .update .column{ min-height: calc(100vh - 130px);}
}

@media screen and (max-width: 832.98px) {
  
}

@media screen and (max-width: 767.98px) {
	.update .fs-18{
		font-size: 16px;
	}
}

@media screen and (max-width: 640.98px) {
	
}

@media screen and (max-width: 575.98px) {

}

@media screen and (max-width: 440.98px) {
	.update .colmn-1 {
    padding: 30px;
	}

	.tab-desc {
    padding: 20px 20px 20px;
	}
}

@media screen and (max-width: 360.98px) {
	.update .fs-18 {
    font-size: 14px;
	}
	
	.update .topic-sub ul li {
    padding: 10px 20px;
	}
}