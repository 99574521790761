
.tutorial-page-main .banner {
  padding: 189px 0 195px;
  /*background: url(../images/tutorial-banner-bg.png);*/
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.tutorial-page-main .banner .custom-content{
  max-width: 984px;
}

.tutorial-page-main .banner .custom-content h2{
  font-size: 50px;
}

.tutorial-page-main .banner .search-box {
  max-width: 100%;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  margin-top: 56px;
  padding: 10px 10px 10px 35px;
}

.tutorial-page-main .banner .search-box input{
  background: transparent;
  outline: transparent;
  border: none;
}

.tutorial-page-main .banner form .search {
  font-size: 30px;
  width: 100%;
  margin-left: 20px;
  color: #DCDCDC;
}

.tutorial-page-main .banner form .search::placeholder {
  color: #DCDCDC;
}

.tutorial-page-main .banner .search-submit {
  background: #0092B7;
  border: none;
  padding: 20px 40px;
  border-radius: 12px;
}

.sec-1{
  padding: 40px 0 50px;
  background: #0c142b; 
  background: -moz-linear-gradient(top,  #0c142b 0%, #1f2c51 100%); 
  background: -webkit-linear-gradient(top,  #0c142b 0%,#1f2c51 100%); 
  background: linear-gradient(to bottom,  #0c142b 0%,#1f2c51 100%); 
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0c142b', endColorstr='#1f2c51',GradientType=0 ); 
}

.sec-1 .custom-content{
  max-width: 1199px;
  margin: 0 auto;
}

.sec-1 .content{
  margin: 53px 0 0;
}

/* .sec-1 .custom-content .colmn{
  background: #121b33;
  border: 2px solid #5C6378;
  border-radius: 10px;
  text-align: center;
  flex: 0 0 25%;
  max-width: 23%;
  margin-bottom:50px;
  height: 290px;
  padding: 20px 53px 0;
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.51);
} */

.sec-1 .custom-content img {
  width: 80px;
}

.sec-1 .custom-content .colmn .img-wrapper{
  margin-bottom: 15px;
}

.sec-2{
  background: #00d4ff;
  background: -moz-linear-gradient(-45deg,  #00d4ff 0%, #9e00ec 100%); 
  background: -webkit-linear-gradient(-45deg,  #00d4ff 0%,#9e00ec 100%);
  background: linear-gradient(135deg,  #00d4ff 0%,#9e00ec 100%); 
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00d4ff', endColorstr='#9e00ec',GradientType=1 ); 
}

.sec-2 .custom-content{
  max-width: 1720px;
  margin: 0 auto;
}

.sec-2 .title{
  margin: 43px 0 50px;
  background: #712DBE;
  border-radius: 20px;
  max-width: max-content;
  padding: 23px 20px 23px 28px;
}

.sec-2 .title p{
  padding-left: 54px;
}

.sec-2 .title p.alt{
  padding-left: 0;
}

.sec-2 .title p::before {
  display: block;
  /*background: url('../images/sec-2-circle.png');*/
  width: 22px;
  height: 22px;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.sec-2 .colmn{
  background: #fff;
  flex: 0 0 25%;
  max-width: 23%;
  border-radius: 20px;
  padding: 17px 14px;
  margin-bottom: 40px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

.sec-2 .colmn p{
  color: #000000;
}

.sec-2 .colmn .vid-title{
  margin-top: 17px !important;
}

.sec-2 .colmn .timer{
  color: #9C9C9C;
}

.sec-2 .colmn .row{
  margin: 0;
}

.sec-2 .divider:first-child{
  top: -4px;
}

.sec-2 .divider:last-child{
  bottom: -4px;
}

.sec-2 .colmn img {
  width: 100%;
}

.sec-3{
  background: #374a81; 
  background: -moz-linear-gradient(top,  #374a81 0%, #4961a4 100%);
  background: -webkit-linear-gradient(top,  #374a81 0%,#4961a4 100%);
  background: linear-gradient(to bottom,  #374a81 0%,#4961a4 100%); 
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#374a81', endColorstr='#4961a4',GradientType=0 );
  padding: 23px 0 63px;
}

.sec-3 .custom-content{
  max-width: 1208px;
  margin: 0 auto;
}

.sec-3 .custom-content .text-wrapper p:first-child{
  margin-bottom: 0;
}

.sec-3 .custom-content .text-wrapper{
  margin-bottom: 40px;
}

.sec-3 .custom-content .text-wrapper .w-87{
  max-width: 87%;
}

.sec-3 a {
  background: #00cb7a;
  background: -moz-linear-gradient(left,  #00cb7a 0%, #0091c8 50%, #00cb7a 100%);
  background: -webkit-linear-gradient(left,  #00cb7a 0%,#0091c8 50%,#00cb7a 100%); 
  background: linear-gradient(to right,  #00cb7a 0%,#0091c8 50%,#00cb7a 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00cb7a', endColorstr='#00cb7a',GradientType=1 );
  background-size: 200% auto;
  border: none;
  padding: 17px 38px 17px 40px;
  border-radius: 100px;
  color: #fff;
}

.sec-3 a:hover {
  background-position: right;
}

.tutorial-page-main .footer {
  position: unset;
  background: #00113E;
}

@media screen and (max-width: 1680.98px) { 
  .sec-2 .custom-wrapper{
    max-width: 90%;
  }
  .sec-2 .custom-content {
    max-width: 98%;
    margin: 0 auto;
  }
}

@media screen and (max-width: 1500.98px) {	

}

@media screen and (max-width: 1440.98px) { 

}

@media screen and (max-width: 1366.98px) {
  .sec-1 .custom-content {
    max-width: 90%;
  }
}

@media screen and (max-width: 1280.98px) {
  h1, .h1 {
		font-size: 70px;
	}

  .sec-3 .custom-content {
    max-width: 90%;
  }
}

@media screen and (max-width: 1199.98px) {
  .tutorial-page-main .banner {
    padding: 170px 0 120px;
  }

  .sec-2 .colmn p {
    font-size: 12px;
  }
}

@media screen and (max-width: 1024.98px) {
  .tutorial-page-main h2, .tutorial-page-main .h2 {
    font-size: 30px;
  }

  .tutorial-page-main .banner .custom-content {
    max-width: 90%;
  }

  .tutorial-page-main .banner .custom-content h2 {
    font-size: 40px;
  }

  .tutorial-page-main .banner .search-box {
    margin-top: 25px;
  }

  .sec-1 .custom-content .colmn{
    padding: 20px 20px 0;
  }

  .sec-2 .colmn{
    flex: 0 0 50%;
    max-width: 48%;
  }

  h3, .h3 {
    font-size: 26px;
  }
}

@media screen and (max-width: 991.98px) {
  .sec-3 .colmn-1{
    margin-bottom: 40px;
  }

  .sec-3 .custom-content .text-wrapper .w-87{
    margin: 0 auto;
  }

  .sec-3 .colmn-2{
    text-align: center;
  }

  .sec-2 .row{
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (max-width: 832.98px) {  
  .sec-1 .custom-content .colmn{
    flex: 0 0 33%;
    max-width: 30%;
    margin: 0 10px 50px 10px;
  }

  .sec-1 .justify-content-between {
    justify-content: center !important;
  }
}

@media screen and (max-width: 767.98px) {
  h2, .h2 {
    font-size: 33px !important;
  }

  h3, .h3 {
    font-size: 27px;
  }

  .md-txt{
    font-size: 18px;
  }
}

@media screen and (max-width: 640.98px) {
  h1, .h1 {
		font-size: 55px;
	}

	h2, .h2 {
		font-size: 30px !important;
	}

  .sec-1 .custom-content {
    max-width: 85%;
  }

  .sec-1 .custom-content .colmn {
    flex: 0 0 50%;
    max-width: 46%;
  }
}

@media screen and (max-width: 575.98px) {
  .tutorial-page-main .banner form .search {
    font-size: 16px;
  }

  .tutorial-page-main .banner .search-submit {
    padding: 10px 30px;
    border-radius: 5px;
  }
}

@media screen and (max-width: 440.98px) {
  h1, .h1 {
		font-size: 45px;
	}

 	h2, .h2 {
		font-size: 28px !important;
	}

  h3, .h3 {
    font-size: 20px;
  }
  
	.md-txt{
		font-size: 16px;
	}

  .sec-1 .custom-content .colmn {
    flex: 0 0 100%;
    max-width: 90%;
    margin: 0 0px 40px 0px;
  }

  .sec-2 .colmn {
    flex: 0 0 100%;
    max-width: 98%;
  }

  .sec-2 .divider:first-child {
    visibility: hidden;
  }

  .sec-3 .custom-content .text-wrapper .w-87 {
    max-width: 100%;
  }
}

@media screen and (max-width: 360.98px) { 
  .fs-15 {
    font-size: 12px;
  }
  
  .sec-2 .divider:first-child {
    top: -10px;
  }
}