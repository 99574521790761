/* .header {
  position: fixed;
  z-index: 9;
  width: 100%;
  padding: 10px 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
} */

.header .custom-wrapper {
  max-width: 90%;
  margin: 0 auto;
}

.header .list {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header .list a {
  color: #fff;
  border-bottom: 2px solid transparent;
}

.header .colmn-1 {
  flex: 0 0 35%;
  max-width: 35%;
}

.header .colmn-2 {
  flex: 0 0 65%;
  max-width: 52%;
}

.header .user-info {
  margin: 0 15px;
  margin-right: 8px;
}

.header .burger {
  display: none;
  width: 30px;
  float: right;
}

.in-in-colmn-1 {
  background: rgb(0,203,122);
  background: -moz-linear-gradient(left,  rgba(0,203,122,1) 0%, rgba(0,145,200,1) 100%);
  background: -webkit-linear-gradient(left,  rgba(0,203,122,1) 0%,rgba(0,145,200,1) 100%);
  background: linear-gradient(to right,  rgba(0,203,122,1) 0%,rgba(0,145,200,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00cb7a', endColorstr='#0091c8',GradientType=1 );
  padding: 12px 16px;
  border-radius: 100px;
}

.in-in-colmn-1,
.in-in-colmn-2 {
  cursor: pointer;
}

.header .notif-count {
  margin: 0 10px;
}

.mobile-menu {
  width: 350px;
  position: fixed;
  z-index: 10;
  background: #00113e;
  height: 100%;
  padding: 30px;
  display: none;
  left: -100%;
  transition: .3s ease-in-out all;
}

.mobile-menu li {
  margin-bottom: 25px;
}

.mobile-menu li a {
  color: #fff;
}

.mobile-menu li:nth-child(1) .user-info {
  margin: 0 15px;
}

.mobile-menu .in-in-colmn-1 .notif-count {
  margin: 0 15px;
}

.mobile-menu .in-in-colmn-1 {
  max-width: max-content;
}

.mobile-menu.show-menu {
  left: 0;
  transition: .3s ease-in-out all;
}

.header.fixed{ position: fixed; width: 100%; left: 0; top: 0;}

@media screen and (max-width: 1500.98px) {
  .header .colmn-2 {
    max-width: 60%;
  }
}

@media screen and (max-width: 1366.98px) {
  .header .fs-15 {
    font-size: 12px;
  }

  .header .md-txt {
    font-size: 16px;
  }

  .header .notif-count {
    font-size: 16px;
  }

  .header .colmn-2 {
    max-width: 60%;
  }
}

@media screen and (max-width: 1199.98px) {
  .header {
    padding: 10px 0;
  }

  .header .colmn-2 {
    max-width: 65%;
  }
  
}

@media screen and (max-width: 1024.98px) {
  .header .colmn-1 {
    max-width: 20%;
  }

  .header .colmn-2 {
    max-width: 70%;
    flex: 0 0 80%;
  }
}

@media screen and (max-width: 991.98px) {
  .header {
    padding: 15px 0;
  }

  /* .header .list {
    display: none!important;
  } */

  .header .burger {
    display: block;
  }

  .mobile-menu {
    display: block;
  }

  .header .headM .list.d-flex{ display: block !important; position: fixed;
    width: 320px;
    z-index: 99;
    background: #00779c;
    left: -320px;
    top: 0;
    height: 100%; padding: 30px; transition: all 0.5s ease;}
}

.header .headM.act .list.d-flex{ left: 0;}
.header .headM.act .list li{ border-bottom: 1px solid rgba(255,255,255,0.3);}
.header .headM.act .list li:last-child{ padding-top: 10px; padding-bottom: 10px;}

@media screen and (max-width: 360.98px) {
  .header {
    padding: 15px;
  }

  .mobile-menu {
    width: 280px;
  }
}