.integration-page-main .footer {
  position: unset;
  background: #00113E;
} 
/* banner  */
.integration-page-main .banner {
  background: #000E34;
  padding: 150px 0 77px;
}
.integration-page-main .banner .custom-wrapper{
  max-width: 1662px;
  margin: 0 auto;
}

.integration-page-main .banner .integrations{
  max-width: 1423px;
  margin: 0 auto;
}
.integration-page-main .banner .title {
  max-width: max-content;
  margin-left: auto;
  margin-right: auto;
}
.integration-page-main .banner .title::after {
  display: block;
  position: absolute;
  width: 93px;
  height: 3px;
  background: #00FFD3;
  margin: auto;
  left: 0;
  right: 0;
  bottom: -15px;
}
.integration-page-main .banner .integrations .content{
  margin: 60px 0 74px;
}
.integration-page-main .banner .integrations .content .col{
  flex: 0 0 14%;
  max-width: 12%;
  background: #1e409c;
  border-radius: 10px;
  /* padding: 30px 42px; */
  padding: 20px;
  box-shadow: 0 3px 6px #000;
  margin: 0 12px 20px;
  transition: all .4s;
  cursor: pointer;
}

.integrations-row-box img {
  width: 70px;
}

.integration-page-main .banner .integrations .content .integrations-row-box:hover{
  background: #00cb7a;
  background: linear-gradient(to right, #00cb7a 0%,#0091c8 100%);
  box-shadow: 0 0 20px 0 #00cb7a, 0 0 20px 0 #0091c8;
}

.integration-page-main .banner .integrations .content .colmn-6 .img-wrapper {
  left: -13px;
  top: 8px;
}
.integration-page-main .banner .integrations .content .img-wrapper {
  margin-bottom: 15px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.integration-page-main .banner .divider{
  background: #707070;
  height: 1px;
}

.integration-page-main .banner .connections{
  margin: 81px 0 74px;
}
.integration-page-main .banner .connections .content{
  margin-top: 60px;
}
.connections .content .row {
  padding: 20px 25px;
  background: #081030;
  border: 1px solid #707070;
  border-radius: 10px;
  scroll-margin: 80px 0 0 0;
}
.connections .content .row:not(:last-child) {
  margin-bottom: 30px;
}

.connections .content .row .text-wrapper p:not(:last-child) {
  margin-bottom: 18px;
}
.connections .content .btn-connect {
  border: 0;
  padding: 12px 20px;
  border-radius: 100px;
}
.connections .content .btns{
  padding: 0;
  background: unset;
  border: 0;
  border-radius: 0;
  gap: 14px;
}
.connections .content .btn-1 {
  background: #00cb7a;
  background: -moz-linear-gradient(left, #00cb7a 0%, #0091c8 100%);
  background: -webkit-linear-gradient(left, #00cb7a 0%,#0091c8 100%);
  background: linear-gradient(to right, #00cb7a 0%,#0091c8 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00cb7a', endColorstr='#0091c8',GradientType=1 );
  width: 153px;
}
.connections .content .btn-2 {
  background: #ff6f00;
  background: -moz-linear-gradient(left, #ff6f00 0%, #cd1b16 39%, #cb1717 100%);
  background: -webkit-linear-gradient(left, #ff6f00 0%,#cd1b16 39%,#cb1717 100%);
  background: linear-gradient(to right, hsl(26, 100%, 50%) 0%,#cd1b16 39%,#cb1717 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff6f00', endColorstr='#cb1717',GradientType=1 );
  width: 174px;
}
.connections .content .row-6 .img-wrapper {
  left: -10px;
  top: 13px;
}
/* banner  */

/* Section 3 Start */
.s3 {
  /*background: url(../images/s3-bg.png);*/
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.s3 .divider{
  max-width: 1228px;
  height: 31px;
  margin: 0 auto;
  top: -4px;
}
.s3 .content-wrapper {
  max-width: 1490px;
  padding: 88px 0 120px;
}

.s3 .demo-btn {
  background: rgb(203,0,196);
  background: -moz-linear-gradient(45deg,  rgba(203,0,196,1) 0%, rgba(110,0,255,1) 100%);
  background: -webkit-linear-gradient(45deg,  rgba(203,0,196,1) 0%,rgba(110,0,255,1) 100%);
  background: linear-gradient(45deg,  rgba(203,0,196,1) 0%,rgba(110,0,255,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cb00c4', endColorstr='#6e00ff',GradientType=1 );
  max-width: max-content;
  padding: 18px 30px;
  border-radius: 100px;
  border: 3px solid #fff;
}

.s3 .demo-btn:hover .play-icon {
  margin-right: 20px;
}

.s3 .demo-btn .play-icon {
  margin-right: 10px;
  transition: .3s ease-in-out all;
}

.demo-vid-wrapper {
  width: 1000px;
}

.demo-modal .modal-content {
  background: #0A1224!important;
  /* background: none !important; */
  /* background: #0091c8;
  background: -moz-linear-gradient(-45deg,  #0091c8 0%, #00cb7a 100%);
  background: -webkit-linear-gradient(-45deg,  #0091c8 0%,#00cb7a 100%);
  background: linear-gradient(135deg,  #0091c8 0%,#00cb7a 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0091c8', endColorstr='#00cb7a',GradientType=1 ); */
}

.demo-modal .modal-header {
  border: none;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: unset;
  }
}

@media screen and (max-width: 1680.98px) { 
  .integration-page-main .banner .custom-wrapper {
    max-width: 92%;
  }
}

@media screen and (max-width: 1500.98px) {	
  .integration-page-main .banner .integrations {
    max-width: 100%;
  }
  .integration-page-main .banner .integrations .content .col {
    padding: 30px 35px;
  }
  .integration-page-main .banner .integrations .row{
    margin-left: 0;
    margin-right: 0;
  }
  .s3 .custom-wrapper {
    max-width: 90%;
  }
  .s3 .divider {
    max-width: 1115px;
  }
}

@media screen and (max-width: 1440.98px) { 
  .integration-page-main .banner .integrations .content .col {
    flex: 0 0 14%;
    max-width: 13%;
  }
}

@media screen and (max-width: 1366.98px) {
  h3, .h3 {
    font-size: 26px;
  }

  h4, .h4 {
    font-size: 22px;
  }
}

@media screen and (max-width: 1280.98px) {
  h1, .h1 {
		font-size: 70px;
	}

  h2, .h2 {
    font-size: 35px;
  }
  .integration-page-main .banner .integrations .justify-content-between {
    -ms-flex-pack: justify !important;
    justify-content: center !important;
  }
  .integration-page-main .banner .integrations .content .col {
    flex: 0 0 25%;
    max-width: 22%;
    margin: 0 15px 20px;
  }
  .integration-page-main .banner .integrations .img-fluid {
    max-width: 50%;
  }
  .connections .img-fluid {
    width: 100% !important;
    height: 100% !important;
  }
  .s3 .divider {
    max-width: 60%;
    top: -6px;
  }
}

@media screen and (max-width: 1199.98px) {
  h6, .md-txt {
    font-size: 16px;
  }  
}

@media screen and (max-width: 1024.98px) {
  .integration-page-main .banner .custom-wrapper {
    max-width: 85%;
  }
  .s3 .divider {
    top: -8px;
  }
}

@media screen and (max-width: 991.98px) {
  .connections .img-fluid {
    width: 15% !important;
    height: 20% !important;
    margin-bottom: 30px;
  }
  .connections .text-wrapper{
    text-align: center;
    margin-bottom: 30px;
  }
  .connections .justify-content-end {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .connections .row{
    margin-left: 0;
    margin-right: 0;
  }
  .integration-page-main .banner .connections {
    margin: 81px 0 74px;
    max-width: 80%;
    text-align: center;
    margin: 81px auto 74px;
  }
}

@media screen and (max-width: 832.98px) {  
  .fs-18 {
    font-size: 12px;
  }
  .integration-page-main .banner .integrations .content .col {
    flex: 0 0 50%;
    max-width: 35%;
  }
}

@media screen and (max-width: 767.98px) {
  h3, .h3 {
    font-size: 27px;
  }

  .md-txt{
    font-size: 18px;
  }
  .connections .img-fluid {
    width: 35% !important;
  }
  .s3 .divider {
    top: -10px;
  }
  .integration-page-main .banner .integrations .content .col {
    padding: 30px 20px;
  }
}

@media screen and (max-width: 640.98px) {
  h1, .h1 {
		font-size: 55px;
	}

	h2, .h2 {
		font-size: 28px;
	}

  h3, .h3 {
    font-size: 25px;
  }

  h4, .h4 {
    font-size: 16px;
  }
}

@media screen and (max-width: 575.98px) {
  .s3 .divider {
    top: -11px;
  }
}

@media screen and (max-width: 440.98px) {
  .md-txt {
    font-size: 16px;
  }   
  .integration-page-main .banner {
    padding: 125px 0 40px;
  }
  .integration-page-main .banner .integrations .content .col {
    flex: 0 0 50%;
    max-width: 38%;
  }   
  .integration-page-main .banner .integrations .content {
    margin: 55px 0 30px;
  }        
  .integration-page-main .banner .connections {
    margin: 40px auto 40px;
  } 
  .s3 .divider {
    top: -12px;
  }
  .connections .content .btn-connect {
    border: 0;
    padding: 12px 20px;
  }   
  .integration-page-main .banner .connections {
    max-width: 100%;
  }    
  .integration-page-main .banner .integrations .content .col {
    flex: 0 0 100%;
    max-width: 60%;
  }                                                                                                         
}

@media screen and (max-width: 360.98px) { 
  .fs-15 {
    font-size: 12px;
  }

  h1, .h1 {
    font-size: 45px;
  }

  h3, .h3 {
    font-size: 20px;
  }

  .md-txt {
    font-size: 14px;
  }
  .connections .content .row {
    padding: 25px 0px;
  }
}