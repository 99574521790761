.training-main-body {
	display: block;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	/* height: 100vh; */
} 

.training-main-body label {
	font-family: 'Poppins', sans-serif;
}

.training-main-body .header {
	/* position: unset; */
}

.training-main-body .footer {
	position: unset;
	background: #00113E;
}

.training .custom-wrap{
	max-width: 100%;
	height: 100%;
}

.training, .training .row, .training .column {
	height: 100%;
}

.training .colmn-1{
	background: #081030;
	padding: 42px 52px;
}

.training .colmn-2{
	background: #38405B;
	padding: 30px 3% 0;
}

.training .sidebar-title{
	margin-bottom: 35px;
}

.training .topics{
	/*padding: 0 50px 37px;*/
	background: #28335A;
	border-radius: 20px;
	overflow: hidden;
}

.training .topic-sub ul li{
	padding: 20px 37px;
}

.training .topic-sub ul li {
	cursor: pointer;
}

.training .topic-sub ul li:hover{
	background: #22459F;
}

.training .vid-content{
	padding: 40px 38px 30px;
	background: #505A7B;
	border-radius: 40px;
	color: #fff;
}

.training .vid-content .scrollable-content{
	/* overflow-y: scroll;
	scrollbar-width: thin;
	scrollbar-color: #009FFF #8F8F8F; 
	scrollbar-border-radius: 50px;
	scrollbar-gutter: stable;
	height: 65vh;*/
	padding-right: 20px;

}

.training-main-body .training {
    position: unset !important;
}

.training .vid-content .colmns{
	/*flex: 0 0 33%;*/
	/*max-width: 30%;*/
	margin-bottom: 55px;
}

.training .vid-content .colmns img {
	width: 100%;
}

.training .vid-content .row{
	margin: 0;
}

.training ::-webkit-scrollbar {
    width: 7px;
}
 
.training ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 25px #8F8F8F; 
    border-radius: 10px;
}
 
.training ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 50px #009FFF; 
}

@media screen and (max-width: 1680.98px) {
 	.training .vid-content .scrollable-content{
		padding-right: 30px;
	}
}

@media screen and (max-width: 1500.98px) {	
	.training .vid-content .colmns{
		margin-bottom: 45px;
	}
}

@media screen and (max-width: 1440.98px) {
	.training .fs-18 {
    font-size: 16px;
	}
}

@media screen and (max-width: 1366.98px) {
}

@media screen and (max-width: 1280.98px) {
	
}

@media screen and (max-width: 1024.98px) {
	.training .fs-18 {
    font-size: 14px;
	}

	.training .vid-content .colmns{
		flex: 0 0 50%;
		max-width: 48%;
	}

	.training .colmn-1 {
    padding: 30px 40px;
	}

	.training .vid-content .colmns{
		margin-bottom: 35px;
	}

	.training .vid-content{
		padding-right: 25px;
	}

	.training .topic-sub ul li {
    padding: 20px 20px;
	}
}

@media screen and (max-width: 991.98px) {
 .training-main-body {
    display: unset;
	}

	.training .colmn-2 {
    padding: 30px;
	}
}

@media screen and (max-width: 832.98px) {
  
}

@media screen and (max-width: 767.98px) {
	.training .fs-18{
		font-size: 16px;
	}
}

@media screen and (max-width: 640.98px) {
	
}

@media screen and (max-width: 575.98px) {
  .training .vid-content .colmns {
    flex: 0 0 100%;
    max-width: 100%;
	}
}

@media screen and (max-width: 440.98px) {
	.training .vid-content .colmns{
		flex: 0 0 100%;
		max-width: 98%;
	}

	.training .vid-content .scrollable-content {
		padding-right: 10px;
	}

	.training .colmn-1 {
    padding: 30px;
	}

	.training .vid-content{
    padding: 30px 20px;
		border-radius: 10px;
	}

}

@media screen and (max-width: 360.98px) {
	.training .fs-18 {
    font-size: 14px;
	}
	
	.training .topic-sub ul li {
    padding: 10px 20px;
	}

	.training .sidebar-title {
    margin-bottom: 20px;
	}
}