html,
body {
    overflow-x: hidden;
}

body {
    scrollbar-width: thin;
    scrollbar-color: #00A5B8 transparent;
}

body::-webkit-scrollbar {
    width: 10px;
}

body::-webkit-scrollbar-thumb {
    background: #00A5B8;
    border-radius: 10px;
}

body::-webkit-scrollbar-track {
    background: #000;
}


@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800&display=swap');

:root {

    --poppins: 'Poppins', sans-serif;
    --Montserrat: 'Montserrat', sans-serif;
}

@media (min-width: 1200px) {
    .container {
        max-width: 1200px;
    }
}

video {
    display: block;
}

.f-900 {
    font-weight: 900;
}

.f-800 {
    font-weight: 800;
}

.f-700 {
    font-weight: 700;
}

.f-600 {
    font-weight: 600;
}

.f-500 {
    font-weight: 500;
}

.f-400 {
    font-weight: 400;
}

.f-300 {
    font-weight: 300;
}

.f-200 {
    font-weight: 200;
}

.f-100 {
    font-weight: 100;
}

.f-italic {
    font-style: italic;
}

.f-uppercase {
    text-transform: uppercase;
}

button {
    cursor: pointer !important;
}

.banner-content .modal-dialog {
    display: none !important;
}

a:hover {
    text-decoration: none !important;
}

header .navbar {
    background-color: #00779C;
    border-bottom: 1px solid #191919;
}


header .navbar .navbar-nav .active {
    position: relative;
}

header .navbar .navbar-nav .active::before {
    content: '';
    position: absolute;
    width: 60px;
    height: 2px;
    background-color: #00F5A0;
    top: 34px;
    left: 0;
    right: 0;
    margin: auto;
}

/*header .dropdown-menu {*/
/*    position: absolute !important;*/
/*    left: -195px !important;*/
/*    top: 44px !important;*/
/*}*/

header .navbar ul li a {
    color: #fff !important;
    font-family: var(--poppins) !important;
    transition: .2s ease-in-out;
}

header .navbar ul li:hover a {
    color: #00FFC2 !important;
    transition: .2s ease-in-out;
}

header .navbar ul li {
    margin-right: 30px;
}


header button#dropdownMenuButton span {
    border-radius: 100%;
    width: 40px;
    height: 40px;
    line-height: 40px;
    display: block;
    background-color: #fff;
    cursor: pointer;
    transition: .2s ease-in-out;

}

/*.dropdown-menu.show {*/
/*    display: block;*/
/*    transition: .2s ease-in-out;*/
/*}*/

header .dropdown-toggle::after {
    display: none !important;
}

header .dropdown button {
    padding: 0;
    border: none;
    background-color: transparent;
}

header .dropdown button:focus {
    box-shadow: none;
    outline: none !important;
}

header .dropdown-item:hover {
    background-image: linear-gradient(to right, #00C2FF, #00E9D8, #00FFC2);
}

.bashboard-banner .banner-items-list {
    background: linear-gradient(0.25turn, #842DD1, #AB2DBA, #E22D9A);
    border-radius: 15px;
    padding: 5px;
}

.bashboard-banner .banner-items p {
    color: #fff !important;
    font-size: 14px;
}

.bashboard-banner .banner-items a {
    margin-top: 15px;
}

.bg2 {
    background: linear-gradient(0.25turn, #2C7180, #1EAB91, #14D29D) !important;
}

.bg3 {
    background: linear-gradient(0.25turn, #2370BE, #4BB1CE, #4BB4CF) !important;
}

.banner-content {
    padding: 136px 0px;
}

.banner-items {
    padding: 136px 0px;
}


.bashboard-banner {
    position: relative;
}

.bashboard-banner::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #15191E;
    top: 0;
    left: 0;
    opacity: 0.8;
}

.bashboard-banner {
    /*background-image: url('../images/Mask\ Group\ 3.png');*/
    background-size: cover;
    background-position: top center;
    width: 100%;

    background-repeat: no-repeat;

}

.banner-content .watch-demo-btn button {
    background-image: linear-gradient(to right, #00C2FF, #00E9D8, #00FFC2);
    padding: 15px 58px;
    border-radius: 30px;
    border: none;
    font-size: 16px;
    font-weight: 600;
    font-family: var(--poppins);
    cursor: pointer;
    overflow: hidden;
}

button:focus {
    box-shadow: none !important;
    outline: none !important;
}



.banner-content .watch-demo-btn button span {
    margin-right: 5px;

}


.banner-content h4 {
    font-size: 39px;
    font-family: var(--Montserrat);
    font-weight: 300;
}

.banner-content h1 {
    font-size: 73px;
    font-weight: 800;
    font-family: var(--Montserrat);
}

.banner-content h1 span {
    color: #00F5A0;
}

.banner-content p {
    width: 544px;
    font-family: var(--poppins);
    font-size: 18px;
    font-weight: 300;
}

.dashborad-sec-3 {
    position: relative;
    overflow: hidden;
    padding: 80px 0px;
    background-color: #15191E;
}

.banner-video-project {
    background-color: #15191E;
    padding: 40px 0px;
}

.banner-video-project .video-project h5 {
    font-size: 40px;
    font-family: var(--poppins);
}

.banner-video-project .video-project h5 span {
    color: #00F8B8;
}


.banner-video-project .video-project a span {
    color: #fff;
    margin-left: 20px;
}

.video-card {
    background-image: linear-gradient(to bottom, #00FDC3, #2DA8C7);
    border-radius: 10px;
    padding-top: 1px;
}


.project-create-btn button:hover {
    color: #2fcac9 !important;
}


.ClipsReel-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .8s ease-in-out;
    background-color: #000000a3;
    border-radius: 10px;
}

.ClipsReel-overlay ul {
    height: auto;
}

.ClipsReel-overlay ul li a span {
    width: 40px;
    height: 40px;
    line-height: 43px;
    text-align: center;
    border-radius: 50%;
    color: #000;
    background-color: #fff;
    cursor: pointer;
    display: inline-block;


}

.vidoe-play-icon span i {
    color: #fff !important;
}


.ClipsReel-overlay ul li:hover i {
    color: #00F5A0 !important;
}

.ClipsReel-overlay ul li:hover a {
    color: #00F5A0;
}

.ClipsReel-overlay ul li a {
    font-size: 12px;
    color: #fff;
}

.ClipsReel-overlay ul li a i {
    font-size: 18px;
    color: #000;
}


.video-img:hover .ClipsReel-overlay {
    opacity: 1;
    transition: .8s ease-in-out;
}



.video-card .video-img {
    border-radius: 5px;
    position: relative;
    margin: 10px;
}

.video-card .video-title {
    padding: 10px;
    padding-top: 0px;
}

.video-card .video-title .vidoe-play-icon span {
    cursor: pointer;

}

.video-name {
    background-color: #B1FCFF;
    width: 100%;
    padding: 5px 10px;
    border-radius: 30px;
}

.video-name p {
    color: #000;
    font-family: var(--poppins);
    font-size: 14px;
    padding-left: 7px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-weight: 600;
}

.vidoe-play-icon span img {
    width: 45px;
}

.banner-video-project .video-project a {
    color: #fff;
    font-size: 25px;
    font-family: var(--poppins);
    transition: .2s ease-in-out;
}

.banner-video-project .video-project a:hover {
    color: #00F5A0;
    transition: .2s ease-in-out;
}

.banner-video-project .video-project a:hover span {
    color: #00F5A0;
}





.dashborad-sec-3-content h4 {
    font-size: 30px;
    font-family: var(--poppins);
    transition: .2s ease-in-out;
}


.dashborad-sec-3-content h4:hover {
    color: #00F5A0;
    transition: .2s ease-in-out;
}

.dashborad-sec-3-content h6 {
    font-size: 18px;
    font-family: var(--poppins);
    color: #7BFFE9;
    text-transform: uppercase;

}

.dashborad-sec-3-content p {
    font-size: 18px;
    font-family: var(--poppins);
    font-weight: 200;
}

.imgsizeleft {
    margin-left: 50px;
}

.imgsizeright {
    margin-left: -320px;
}


.logoimg {
    /*background-image: url('../images/BG.png');*/
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
}



.login-box {
    padding: 100px 0px 150px;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-content h1 {
    font-size: 55px;
    font-family: var(--Montserrat);
    font-weight: 800;
}

.login-content h1 span {
    color: #00F8B8;
}

.login-content p {
    width: 90%;
    font-size: 18px;
    font-family: var(--poppins);
    font-weight: 300;
    margin: auto;
    color: #C1C1C1;
}

.login-content h5 {
    font-size: 29px;
    font-family: var(--poppins);
}



.login-form {
    padding: 50px;
    background-color: #101010;
    border-radius: 25px;
    border: 1px solid#00E5FF !important;
}

.login-form h4 span {
    color: #00F8B8;
}

.login-form h4 {
    font-family: var(--Montserrat);
    font-size: 26px;

}

.login-form button {
    background-image: linear-gradient(to left, #00D3EB, #16B891);
    border: none;
    color: #fff;
    font-size: 18px;
    letter-spacing: 1px;
    font-weight: 600;
    font-family: var(--poppins);
    cursor: pointer;
}

.login-form button:focus {
    color: #00b889 !important;
    background-color: #fff !important;
}

.login-form button:focus {
    background-image: none !important;
}

.login-form .forgot a {
    color: #C3C3C3 !important;
}

.login-form .forgot span:hover {
    color: #00F5A0;
    transition: .2s ease-in-out;
}

.login-form .form-group input {
    background-color: transparent !important;
    border-color: #34B8CC !important;
    border-radius: 10px !important;
}

.login-form .form-group input:focus {
    box-shadow: none !important;
}

.login-form .form-group .form-control {
    color: #fff !important;
}


.login-form a span {
    color: #5EACB1;
    margin-left: 5px;
    transition: .2s ease-in-out;
}

.footer .footer-menu ul li a {
    color: #fff;
    font-size: 16px;
    font-family: var(--poppins);
    transition: .2s ease-in-out;
}

.footer .footer-menu ul li a:hover {
    color: #00F5A0;
    transition: .2s ease-in-out;
}

.footer-copyright p a:hover {
    color: #00F5A0;
    transition: .2s ease-in-out;
}

.footer .footer-menu ul li+li {
    margin-left: 40px;
}

.footer .footer-copyright p {
    font-size: 16px;
    font-family: var(--poppins);
}

.footer .footer-copyright p a {
    transition: .2s ease-in-out;
}

.footer {
    background-color: #0000007a;
    padding: 10px 0px;
}



.footerbg {
    background-color: #000000;
    padding: 10px 0px;
}




.banner-items .banner-items-list .col-md-3 img {
    transform: scale(1);
    transition: .4s ease-in-out;
}

/* .banner-items .banner-items-list{
    transform: scale(1);
    transition: .4s ease-in-out;
}

 .banner-items .banner-items-list:hover{
    transform: scale(1.05);
    transition: .4s ease-in-out;
}  */

.banner-items .banner-items-list:hover .col-md-3 img {
    transform: scale(0.9);
    transition: .4s ease-in-out;
}



.project-banner {
    padding: 20px 0px;
    background-color: #15191E;
}


.project-header-name .project-conut h6 {
    color: #A2A2A2;
    font-size: 25px;
    font-family: var(--poppins);
    margin-left: 30px;
    position: relative;
    font-weight: 200;
}

.project-header-name .project-conut h6::before {
    content: '';
    position: absolute;
    width: 2px;
    height: 20px;
    background-color: #A2A2A2;
    top: 3px;
    left: -15px;
}



.project-header-name .project-item {
    font-size: 25px;
    font-family: var(--poppins);
    font-weight: 600;
}



.video-img img {
    width: 100%;
    height: 192px;
}


.project-button .project-create-btn button {
    padding: 10px 20px;
    border-radius: 10px;
    color: #fff;
    background-image: linear-gradient(to right, #36D6FF, #C867FF);
    border: none;
    font-size: 16px;
    font-family: var(--poppins);
    font-weight: 600;
    cursor: pointer;
}

.project-button .project-create-btn button:focus {
    background-color: #fff !important;
    background-image: none !important;
    color: #2fcac9 !important
}

.project-input-box .input-group .input-group-prepend .input-group-text {
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    background: #fff;
    border: none !important;
    color: #979797;
    padding-top: 10px;
    font-size: 14px;
}

.project-input-box .input-group .input-group-prepen {
    margin-right: 0px !important;
}

.project-input-box .input-group input {
    border: none !important;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    height: 44px;
}

.project-input-box .input-group input:focus {
    box-shadow: none !important;
}

.project-input-box ::placeholder {
    color: #7A7A7A !important;
    font-size: 14px;
    font-family: var(--poppins);
}

.project-input-box {
    margin-right: 20px;
}

.project-banner .bottom-line {
    border-bottom: 1px solid #A2A2A2;
    padding-bottom: 20px;
}

.project-banner {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}



.login-form .form-group label {
    font-size: 20px;
    font-family: var(--poppins);
    color: #C3C3C3;
    margin-left: 10px;
}

.login-form .form-group input {
    height: 50px;
}

.login-form ::placeholder {
    font-size: 16px;
    color: #707070;
    font-family: var(--poppins);
    padding-left: 10px;
}

.btn-change6 {

    box-shadow: 0 0 1px #ccc;
    -webkit-transition-duration: .3s;
    transition-duration: .3s;
    -webkit-transition-timing-function: linear;
    transition-timing-function: linear;
    box-shadow: 0 0 0 #fff inset;
    cursor: pointer;

}

.login-form button:hover {
    color: #00b889 !important;
}



.watch-demo-btn button {
    position: relative;
    z-index: 999;
}

.watch-demo-btn button::before {
    content: '';
    width: 0%;
    height: 100%;
    background-color: #fff;
    top: 0;
    left: 0;
    position: absolute;
    border-radius: 30px;
    color: #00F5A0;
    transition: .3s ease-in-out;
    z-index: -1;
}

.watch-demo-btn button:hover::before {
    width: 100%;
    transition: .3s ease-in-out;
    border-radius: 30px;
}

.watch-demo-btn button:hover {
    color: #00b889 !important;
    transition: .4s ease-in-out;
}






.btn-change7 {

    box-shadow: 0 0 1px #ccc;
    -webkit-transition-duration: 1s;
    transition-duration: 1s;
    -webkit-transition-timing-function: linear;
    transition-timing-function: linear;
    box-shadow: 0 0 0 #383a3b inset;
}

.btn-change7:hover {
    box-shadow: 550px 0 0 #2DA8C7 inset;
}




.select-box .form-group {
    width: 260px;
    border: 1px solid #00FFD4;
    border-radius: 5px;
    margin-right: 20px !important;
}

.select-box .form-group select {
    border: none !important;
}

.select-box .form-group select:focus {
    box-shadow: none !important;
}

.select-box .form-group select {
    background-color: #101010;
}

.search-select .input-group .input-group-prepend .input-group-text {
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    background: #fff;
    border: none !important;
    color: #979797;
    padding-top: 10px;
    font-size: 14px;
}

.search-select .input-group .input-group-prepen {
    margin-right: 0px !important;
}

.search-select .input-group input {
    border: none !important;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    height: 44px;
}

.search-select .input-group input:focus {
    box-shadow: none !important;
}

.search-select ::placeholder {
    color: #8b7676 !important;
    font-size: 14px;
    font-family: var(--poppins);
}



.premium-header {
    background-color: #303030;
    padding: 15px 0px;
}

.select-box select {
    color: #fff !important;
}


.template-name h4 {
    font-size: 22px;
    font-family: var(--poppins);
    font-weight: 400;
}



.template-number h6 {
    color: #A2A2A2;
    font-size: 22px;
    font-family: var(--poppins);
    margin-left: 30px;
    position: relative;
    font-weight: 200;
}


.template-number h6::before {
    content: '';
    position: absolute;
    width: 2px;
    height: 18px;
    background-color: #A2A2A2;
    top: 4px;
    left: -15px;
}


.template-body {
    padding: 60px 0px;
    background-color: #171717;
    min-height: 100vh;
}

.template-names h6 {
    color: #DEDFDD;
}


.template-body .row {
    overflow-y: auto;
    height: 60vh;
}




.template-body ::-webkit-scrollbar {
    width: 5px;
}

.template-body ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

.template-body ::-webkit-scrollbar-thumb {
    background: #00F5A0;
    border-radius: 10px;
    height: 10vh !important;
}



.modal-scrollbar {
    height: 60vh;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: thin;
    scrollbar-color: #00F5A0 transparent;
}

.modal-scrollbar::-webkit-scrollbar {
    width: 5px;
}

.modal-scrollbar::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

.modal-scrollbar::-webkit-scrollbar-thumb {
    background: #00F5A0 !important;
    border-radius: 10px;
    height: 10vh !important;
}



/* .template-body ::-webkit-scrollbar-thumb:hover {
    background: #b30000;
  } */

.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    display: none;
    overflow: hidden;
    outline: 0;
    opacity: 1;
    background: rgba(0, 0, 0, .3);
}

.modal-header .close {
    padding: 0px !important;
    margin: 0px !important;
}

.modal-header {
    width: 100%;
    margin: auto;
}

.modal-dialog {
    max-width: 1000px !important;

}

.editor-sec .modal-content {
    background: #0A1224 !important;
    color: #fff !important;
}

.modal-title button span {
    color: #fff !important;
}

.facebook-item-box {
    border: 1px solid#707070;
    border-radius: 10px;
    background-color: #1C1C1C;
}

.facebook-item-box h6 {
    color: #6A6A6A;
}

.facebook-item-box h5 {
    font-size: 14px;
}

.vidoe-play-icon {
    margin-top: 6px;
}



/* editor css start */
.editor-sec .editor-container .colmn-2 {
    /* flex: 0 0 100%; */
    /* max-width: 77%; */
    width: calc(100% - 500px);
}

.colmn-preview-wrapper {
    height: 100vh;
}

.editor-sec .editor-container .colmn-1 {
    /* flex: 0 0 100%; */
    /* max-width: 30%; */
    width: 500px;
}


.editor-sec {
    background: #000000;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.save-render-sec {
    background: #0A1224;
    padding: 10px;
    /* margin-left: auto; */
}

.save-render-sec button {
    font-size: 14px;
}

/* .render-projectbtn {
    margin-right: 85px;
} */
.editor-part {
    overflow-y: auto;
    overflow-x: hidden;
    background: #39435B;
    height: 100%;
    scrollbar-width: thin;
    scrollbar-color: #00F5A0 transparent;
}

.editor-part::-webkit-scrollbar {
    width: 5px;
    height: 10em;
}

.editor-part::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px grey;
    border-radius: 10px;
}

.editor-part::-webkit-scrollbar-thumb {
    background: #00F5A0 !important;
    border-radius: 10px;
    height: 10px;
}

.save-render-sec .add-campaign input {
    border-radius: 30px;
    border: none;
    background: #161616;
    width: 290px;
    height: 42px;
    font-size: 14px;
}

.save-render-sec ::placeholder {
    color: #A5A5A5;
}

.save-render-sec .add-campaign .form-control {
    color: #A5A5A5;
}

.save-render-sec .save-projectbtn button {
    /* background: linear-gradient( to right , #00FFBA , #3B91CB); */
    background: #ffffff;
    background: -moz-linear-gradient(left, #ffffff 0%, #ffffff 50%, #00ffba 50%, #3b91cb 100%);
    background: -webkit-linear-gradient(left, #ffffff 0%, #ffffff 50%, #00ffba 50%, #3b91cb 100%);
    background: linear-gradient(to right, #ffffff 0%, #ffffff 50%, #00ffba 50%, #3b91cb 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#3b91cb', GradientType=1);
    background-size: 202%;
    background-position: 99% 50%;
    transition: .3s ease-in-out;
    padding: 7px 25px;
    border-radius: 5px;
    border: none;
    color: #fff;
}

.save-render-sec {
    /* background: linear-gradient(to right , #A72CE3 , #CB3B71); */
    background: #ffffff;
    background: -moz-linear-gradient(left, #ffffff 0%, #ffffff 50%, #a72ce3 50%, #cb3b71 100%);
    background: -webkit-linear-gradient(left, #ffffff 0%, #ffffff 50%, #a72ce3 50%, #cb3b71 100%);
    background: linear-gradient(to right, #ffffff 0%, #ffffff 50%, #a72ce3 50%, #cb3b71 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#cb3b71', GradientType=1);
    background-size: 202%;
    background-position: 99% 50%;
    transition: .3s ease-in-out;
    padding: 7px 18px;
    border-radius: 5px;
    border: none;
    color: #fff;
}

.render-projectbtn button {
    /* background: linear-gradient(to right , #A72CE3 , #CB3B71); */
    background: #ffffff;
    background: -moz-linear-gradient(left, #ffffff 0%, #ffffff 50%, #c27510 50%, #c92c10 100%);
    background: -webkit-linear-gradient(left, #ffffff 0%, #ffffff 50%, #c27510 50%, #c92c10 100%);
    background: linear-gradient(to right, #ffffff 0%, #ffffff 50%, #c27510 50%, #c92c10 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#cb3b71', GradientType=1);
    background-size: 202%;
    background-position: 99% 50%;
    transition: .3s ease-in-out;
    padding: 7px 25px;
    border-radius: 5px;
    border: none;
    color: #fff;
}

.save-render-sec .preview-projectbtn button {
    /* background: linear-gradient(to right , #A72CE3 , #CB3B71); */
    background: #ffffff;
    background: -moz-linear-gradient(left, #ffffff 0%, #ffffff 50%, #a72ce3 50%, #cb3b71 100%);
    background: -webkit-linear-gradient(left, #ffffff 0%, #ffffff 50%, #a72ce3 50%, #cb3b71 100%);
    background: linear-gradient(to right, #ffffff 0%, #ffffff 50%, #a72ce3 50%, #cb3b71 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#cb3b71', GradientType=1);
    background-size: 202%;
    background-position: 99% 50%;
    transition: .3s ease-in-out;
    padding: 7px 25px;
    border-radius: 5px;
    border: none;
    color: #fff;
}

.editor-tab-menu .nav-item .nav-link h6 {
    color: #fff;
    font-size: 14px;
    font-family: var(--poppins);
    margin-bottom: 0px;
}

.editor-tab-menu .nav-item {
    padding: 0px 3px;
}

.editor-tab-menu .nav-item .nav-link.active {
    background: #00A5B8;
}

.editor-tab-menu .nav-item .nav-link h6 {
    font-weight: 500;
}

.editor-tab-menu .nav-item .nav-link.active svg path {
    fill: #00FFB1;
}

.editor-tab-menu .nav-item .nav-link {
    padding: 13px 15px;
    display: flex;
    align-items: center;
    border-radius: 50px;
    background: #0E172D;
}

.editor-tab-menu .nav-item .nav-link .img-wrapper {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.editor-tab-menu {
    padding: 27px 10px;
    background: #293758;
}

.editor-preview {
    padding: 3vh 2vw;
    background: #16213B;
    position: relative;
    overflow-y: auto;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    scrollbar-width: thin;
    scrollbar-color: #00F5A0 transparent;
}

.editor-preview::-webkit-scrollbar {
    width: 5px;
}

.editor-preview::-webkit-scrollbar-thumb {
    background: #00f5a0;
    border-radius: 10px;
}

.editor-preview::-webkit-scrollbar-track {
    background: #0000;
}

.aspect-ratio-wrapper {
    position: absolute;
    width: 100%;
    height: 432px;
    overflow: hidden;
    border-radius: 20px;
    margin: auto;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: 0px 0px 15px #00000087;
    background: radial-gradient(black, transparent);
}

.aspect-ratio-wrapper .canvas-container,
.aspect-ratio-wrapper .canvas-container .preview-wrapper {
    width: 100% !important;
    /* height: 100%!important; */
}

.mv-part-sec .mv-para p {
    font-size: 12px;
    font-family: var(--poppins);
    color: #fff;
}

.mv-part-sec .mv-para {
    background-color: #242424;
    padding: 20px;
    border-radius: 30px;
    border: 1px solid #707070;
    cursor: pointer;
}

.editor-preview-box {
    max-width: 768px;
    height: auto;
    margin: auto;
    border-radius: 20px;
    overflow: hidden;
}

.aspect-ratio-wrapper img,
.preview-def-wh,
.aspect-ratio-wrapper video {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 432px;
}

.para-active {
    background-color: #2DA882 !important;
}

.para-unactive {
    background: none;
}

.timeline-settings,
.expand-btn {
    cursor: pointer;
}

.slide-box ul li {
    flex: 0 0 auto;
    width: 117px;
    height: 54px;
    margin: 0px 20px 0 0;
    overflow: hidden;
    border-radius: 7px;
    text-align: center;
    display: flex;
    align-items: center;
    border: 1px solid #606060;
}

.slide-box ul li .box-ar-wrapper img,
.slide-box ul li .box-ar-wrapper video {
    object-fit: cover;
    object-position: 50% 50%;
    width: 100%;
    height: 100%;
}

.slide-box {
    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-width: thin;
    scrollbar-color: #00F5A0 transparent;
}

.slide-box ul {
    position: relative;
    padding-left: 30px !important;
    padding-right: 10px !important;
}

.add-more-image.plus-icon {
    position: absolute;
    right: -70px;
    top: 21px;
}

.slide-box::-webkit-scrollbar-thumb {
    background: #959595;
    border-radius: 10px;
}

.slide-box::-webkit-scrollbar-track {
    background: #474747;
}

.slide-box::-webkit-scrollbar {
    height: 8px;
}

.slide-sec ul {
    width: max-content;
    padding: 20px 0;
    justify-content: center;
}

html {
    scroll-behavior: smooth;
}

.navbar {
    position: sticky;
    top: 0;

}

.slide-sec {
    background-color: #000E1D;
    position: sticky;
    bottom: 0;
    width: 100%;
    z-index: 1;
    padding: 15px 0;
    z-index: 2;
}

.slide-sec .container-fluid {
    max-width: 1720px;
}

.slide-box .plus-icon i {
    color: #565656;
}

/* Control Box Start */

.control-time {
    width: 100%;
    background: transparent;
    border: none;
    color: #fff !important;
    font-size: 0.7vw;
    margin-right: 10px;
}

.slide-box ul li:hover .overlay__media__wrapper.forVid {
    opacity: 1;
    visibility: visible;
}

.vid-dur {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 2;
    color: #FFF;
}

.vid-dur .icon {
    width: 30px;
    height: 30px;
    background: #5a68c1;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.vid-dur .icon svg {
    width: 13px !important;
}

.vid-dur span {
    display: inline-block;
    background: #5a68c1;
    padding: 0 8px;
    border-radius: 50px;
    font-size: 12px;
}

.wrap-intro .vid-dur {
    top: 45px;
}

.vid-dur.ex {
    right: 17px;
}

.vid-dur.del {
    right: 5px;
    top: 5px;
}

.vid-dur.del .icon {
    width: 25px;
    height: 25px;
}

.control-time:focus-visible {
    outline: none !important;
}

.control-time::placeholder {
    color: #fff;
}

.ct-end {
    text-align: right;
}

.controls-box {
    background: #000;
    padding: 2vh 0.5vw;
    width: 100%;
    max-width: 900px;
    margin: auto;
    position: relative;
    z-index: 1;
    border-radius: 15px;
    box-shadow: 0 3px 5px -1px #000;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 20px;
}

.play-push-left-right {
    max-width: 45%;
}

.play-push-left-right ul img {
    max-width: 1.1vw;
}

.control-time:focus {
    box-shadow: none !important;
}

/* Control Box End */

.save-projectbtn button:hover {
    color: #2fcac9;
}

.save-projectbtn button:focus {
    background-color: #fff !important;
    background-image: unset !important;
    color: #2fcac9 !important;
}

.add-campaign input:focus {
    box-shadow: none;
}

.render-projectbtn button:hover {
    color: #2fcac9;
}

.render-projectbtn button:focus {
    background-color: #fff !important;
    background-image: unset !important;
    color: #2fcac9 !important;
}


.in-out-upload-box {
    background-color: #394768;
    height: 77px;
    border: 1px dashed #707070;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.in-out-upload-box .custom-file-input {
    position: absolute;
    top: 0;
    opacity: 0;
    background: red;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.in-out-upload-box h6 {
    font-size: 12px;
    margin-bottom: 0px;
    color: #fff;
}

.in-out-upload-box .custom-file {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.in-out-part-sec {
    padding: 24px 15px 0;
}

.in-out-box-wrapper {
    background: #16213B;
    padding: 20px 14px;
    border-radius: 10px;
}

.in-out-remove-logo button {
    font-size: 10px;
    border-radius: 100px !important;
}

.in-out-upload-part .in-out-remove-logo .form-check-input {
    position: unset;
}

.in-out-upload-part .in-out-remove-logo .form-switch {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.in-out-remove-logo .remove-logo-switch-btn {
    width: 100%;
}

.remove-logo-switch-btn .form-check label {
    font-size: 10px;
    color: #8F8F8F;
}

.remove-logo-switch-btn .form-check .form-check-input {
    margin-top: 2px;
    margin-left: 10px;
    font-size: 24px;
    background-position: calc(100% - 90%) calc(100% - 0px);
}

.in-out-watermark .remove-logo-switch-btn .form-check .form-check-input {
    background-position: calc(100% - 90%) calc(100% - 0px);
}

/* editor text customization start */
.editor-main {
    /* display: flex;
    flex-direction: column;
    justify-content: space-between; */
    height: 100vh;
    /* width: 100%; */
}

.editor-sec .editor-container .colmn-2::-webkit-scrollbar-thumb {
    background: #00F5A0;
    border-radius: 10px;
}

.editor-sec .editor-container .colmn-2::-webkit-scrollbar-tracks {
    background: #0000;
}

.editor-sec .editor-container .colmn-2::-webkit-scrollbar {
    width: 10px;
}

.editor-preview-wrapper {
    border-radius: 10px;
    width: 100%;
    max-width: 95%;
    height: auto;
    margin: 0 auto;
}

.editor-sec .editor-container .colmn-1 {
    height: 100%;
}

/* .editor-preview, */
.editor-part .tab-content,
.editor-sec .editor-container {
    height: 100%;
}

.editor-text-customization {
    padding: 10px;
}

#pills-media,
#pills-images,
#pills-video,
#pills-medialibrary,
#pills-audio,
#pills-mvlibrary,
#pills-mv {
    overflow: hidden;
    /* height: 88%; */
}

.search-load-box,
/* .scroll-wrapper,  */
.upload-audio-box {
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 12px 0 0;
}

.search-load-box-altx {
    padding: 0;
}

.search-load-box-altx .infinite-scroll-component__outerdiv {
    padding-right: 12px;
}

.search-load-box.alt {
    padding-right: 0;
}

/* .search-load-box {
    height: calc(100vh - 170px) !important;
} */
/* 
.scroll-wrapper > .row {
    height: 67vh; 
}
*/
.nav-link {
    padding: .5rem;
}

header .nav-link.active,
header .nav-link:hover {
    border-bottom: 2px solid #00c780 !important;
}

.active {
    color: red;
}

.streaming-projects-box {
    background: #0077FF;
    border-radius: 20px;
    padding: 10px;
    margin-bottom: 30px;
}

.upload-audio-box>.row {
    height: 620px;
}

.editor-part .tab-pane {
    height: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
    padding: 10px 0;
    scrollbar-width: thin;
    scrollbar-color: #00A5B8 transparent;
}

#pills-text {
    overflow-y: visible !important;
}

/* SCROLL BAR  */

.editor-part .tab-pane,
.search-load-box,
/* .scroll-wrapper,  */
.upload-audio-box {
    scrollbar-width: auto;
    scrollbar-color: #00A5B8 transparent;
}

.editor-part .tab-pane::-webkit-scrollbar-thumb,
.search-load-box::-webkit-scrollbar-thumb,
/* .scroll-wrapper::-webkit-scrollbar-thumb, */
.upload-audio-box::-webkit-scrollbar-thumb {
    background: #00A5B8;
    border-radius: 10px;
}

.editor-part .tab-pane::-webkit-scrollbar-track,
.search-load-box::-webkit-scrollbar-track,
/* .scroll-wrapper::-webkit-scrollbar-track, */
.upload-audio-box::-webkit-scrollbar-track {
    background: #0000;
}

.editor-part .tab-pane::-webkit-scrollbar,
.search-load-box::-webkit-scrollbar,
/* .scroll-wrapper::-webkit-scrollbar, */
.upload-audio-box::-webkit-scrollbar {
    width: 5px;
}


.scrollSt {
    height: calc(100vh - 235px);
}

.editor-sec .tab-pane {
    padding-bottom: 0 !important;
}

.scrollSt {
    scrollbar-width: auto;
    scrollbar-color: #00A5B8 transparent;
}

.scrollSt::-webkit-scrollbar-thumb {
    background: #00A5B8;
    border-radius: 10px;
}

.scrollSt::-webkit-scrollbar-track,
/* .scroll-wrapper::-webkit-scrollbar-track, */
.upload-audio-box::-webkit-scrollbar-track {
    background: #0000;
}

.scrollSt::-webkit-scrollbar,
/* .scroll-wrapper::-webkit-scrollbar, */
.upload-audio-box::-webkit-scrollbar {
    width: 5px;
}


.infinite-scroll-component__outerdiv {
    scrollbar-width: thin;
    scrollbar-color: #00A5B8 transparent;
    /*overflow-x: hidden;*/
}

.infinite-scroll-component__outerdiv::-webkit-scrollbar-thumb {
    background: #00A5B8;
    border-radius: 10px;
}

.infinite-scroll-component__outerdiv::-webkit-scrollbar {
    width: 10px;
}

.infinite-scroll-component__outerdiv {
    scrollbar-width: thin;
    scrollbar-color: #00A5B8 transparent;
    /* please don't add this commented css this is for lazy loading */
    /*overflow-y: scroll !important;*/
    /*overflow-x: hidden !important;*/
}

.infinite-scroll-component__outerdiv::-webkit-scrollbar-thumb {
    background: #00A5B8;
    border-radius: 10px;
}

.infinite-scroll-component__outerdiv::-webkit-scrollbar {
    width: 10px;
}


.infinite-scroll-component.row {
    height: 80vh !important;
    overflow: unset !important;
}

#pills-emoji .infinite-scroll-component.row img {
    height: 70px;
    width: auto;
}

/* #pills-emoji .infinite-scroll-component.row {
    height: unset!important;
} */

.editor-text-customization .editor-text-family {
    position: relative;
    flex: 0 0 100%;
    max-width: 100%;
}

.editor-text-customization .editor-text-weight-size {
    flex: 0 0 29%;
    max-width: 29%;
}

.editor-text-customization select~.icon {
    position: absolute;
    top: 50%;
    right: 15px;
    line-height: 0;
    transform: translateY(-50%);
    color: #fff !important;
}


.editor-color-wrapper label {
    color: #fff !important;
}

.editor-text-customization .editor-input-dark {
    color: #fff !important;
    padding: 5px 10px 5px;
    border-radius: 4px !important;
    border: 1px solid #959595;
    background-color: #394768 !important;
    height: auto;
    box-shadow: none;
    resize: none;
    height: 43px !important;
    letter-spacing: 1px;
}

.editor-text-customization textarea.editor-input-dark {
    height: 130px !important;
}

.editor-text-customization select {
    padding: 7px 12px;
    font: 500 14px/normal "Poppins", sans-serif;
}

.editor-text-customization .txt-edit-btn {
    flex: 16.6666%;
    max-width: 16%;
}

.editor-text-alignment .txt-edit-btn {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 36px;
    height: 36px;
}

.editor-text-customization select:focus,
.editor-text-customization input:focus,
.editor-text-customization button.active {
    border-color: #00A5B8;
}

.editor-text-customization #t-lineheight {
    padding-left: 32px;
}

.editor-text-customization .input-group-prepend {
    position: relative;
    flex: 0 0 31%;
    max-width: 31%;
}

.editor-text-align .txt-edit-btn {
    flex: 0 0 17.25%;
    max-width: 16.5%;
}

.editor-text-customization .input-group-prepend .icon {
    position: absolute;
    top: 45%;
    left: 10px;
    z-index: 1;
    transform: translateY(-50%);
    color: #747474;
}

.editor-font-color #tColor-hex,
.editor-font-color #tBg-hex {
    padding-left: 40px;
}

.editor-font-color .input-color {
    position: relative;
    flex: 0 0 50%;
    max-width: 48.7%;
}

.editor-font-color .editor-rgb-wrapper {
    flex: 0 0 16%;
    max-width: 16%;
}

.editor-font-color #t-color-btn,
.editor-font-color #t-bg-btn {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 50%;
    left: 8px;
    border-radius: 3px;
    border: 2px solid hsla(0, 0%, 100%, .5);
    z-index: 1;
    transform: translateY(-120%);
    transition: none !important;
}

.editor-font-color .swatch,
.editor-font-color .no-color {
    height: 35px;
    border: 0;
    border-radius: 2px;
    padding: 0;
    flex: 0 0 9%;
    max-width: 8.6%;
    margin: 0px 2px 2px 0;
    overflow: hidden;
    outline: none !important;
    position: relative;
}

.editor-font-color .no-color::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 2px;
    height: 50px;
    background: #a2000f;
    transform: rotate(45deg);
    margin: auto;
}

.editor-wrapper {
    padding: 10px 15px;
    position: relative;
}

.editor-font-color,
.editor-text-wrapper {
    background: #16213B;
    border-radius: 10px;
}

/* editor text customization End */

/* media tab css start */
.media-menu-box .nav .nav-item,
.mv-menu-box .nav .nav-item {
    flex: 0 0 33.33%;
    text-align: center;
}

.media-menu-box .nav .nav-item:nth-child(2),
.mv-menu-box .nav .nav-item:nth-child(2),
.audio-box-menu .nav .nav-item:nth-child(2) {
    border-left: 1px solid #3e3e3e;
    border-right: 1px solid #3e3e3e;
}

/* ---------------- */
.media-menu-box .nav,
.mv-menu-box .nav {
    border-radius: 100px;
    overflow: hidden;
    padding: 0 !important;
}

.media-menu-box .nav-item .nav-link,
.mv-menu-box .nav-item .nav-link {
    color: #737373;
    background-color: #000;
    padding: 12px 0px;
    font-size: 13px;
    font-family: var(--poppins);
}



.media-menu-box .nav-item .nav-link.active,
.mv-menu-box .nav-item .nav-link.active {
    background-color: #0082C5;
    color: #fff;
}

.media-search-box .input-group .input-group-prepend .input-group-text,
.mv-search-box .input-group .input-group-prepend .input-group-text {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: #fff;
    border: 1px solid #fff;
    color: #979797;
    cursor: pointer;
}

.media-search-box .input-group input,
.mv-search-box .input-group input {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border: none;
    height: 45px;
}

.media-search-box .input-group-prepend,
.mv-search-box .input-group-prepend {
    margin-left: 0px;
}

.media-search-box input:focus,
.mv-search-box input:focus {
    box-shadow: none;
}

.search-load {
    background-color: #101010;
    height: 100px;
    border: 1px solid #707070;
    overflow: hidden;
}

.search-load-box .colmn {
    margin-bottom: 20px;
}

.media-upload-box button,
.mv-upload-box button {
    background: #4ac6ff;
    background: -moz-linear-gradient(left, #4ac6ff 0%, #b376ff 100%);
    background: -webkit-linear-gradient(left, #4ac6ff 0%, #b376ff 100%);
    background: linear-gradient(to right, #4ac6ff 0%, #b376ff 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4ac6ff', endColorstr='#b376ff', GradientType=1);
    transition: .5s ease-in-out all;
    border: none;
    color: #fff;
    border-radius: 5px;
    height: 100%;

}

.hover-effect {
    background-position: right !important;
}

.media-upload-box,
.mv-upload-box {
    position: relative;
    height: 45px;
}

.mv-upload-box-nx {
    height: inherit !important;
}

.media-upload-box .custom-file,
.mv-upload-box .custom-file {
    position: absolute;
    top: 0px;
    height: 100%;
}

.media-upload-box .custom-file .custom-file-input,
.mv-upload-box .custom-file .custom-file-input {
    opacity: 0;
    background-color: red;
    border-radius: 30px;
    font-size: 2px;
    position: absolute;
    top: 0px;
    cursor: pointer;
    height: 100%;
}

.editor-text-color label {
    color: #fff;
}

/* media start css */


/* audio editor css start */
#music-library-nav {
    border-radius: 0;
}

#music-library-nav .nav-item {
    flex: 0 0 50%;
    border-right: none;
    padding: 0px 15px !important;
}

#music-library-nav .nav-item .nav-link {
    background: none;
    border: none;
}

#music-library-nav .nav-item .nav-link.active {
    border-bottom: 2px solid #10ca9c;
}

#pills-sonority.sonority-wrapper .card {
    background: #6326ac;
    background: -moz-linear-gradient(top, #6326ac 0%, #7677f6 100%);
    background: -webkit-linear-gradient(top, #6326ac 0%, #7677f6 100%);
    background: linear-gradient(to bottom, #6326ac 0%, #7677f6 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#6326ac', endColorstr='#7677f6', GradientType=0);
    border: 0;
}

#pills-sonority.sonority-wrapper .card .card-footer {
    background: #570ea9;
}

.audio-box-menu .tab-pane {
    padding: 10px;
}

.audio-box-menu .nav .nav-item {
    flex: 0 0 33.33%;
    text-align: center;
}

.audio-music-box .card-body img,
.upload-music-box .card-body img {
    width: 45%;
}

.audio-box-menu .tab-pane .colmn,
.audio-music-box .card .card-footer .col-md-9 {
    padding: 0 5px;
}

.audio-box-menu .tab-pane .row .colmn {
    flex: 0 0 33.333%;
    max-width: 33.333%;
    margin-bottom: 10px;
}

.audio-box-sec .audio-box-menu .nav-item .nav-link.active {
    background-color: #0082C5;
    color: #fff;
}

.audio-box-menu .nav {
    padding: 0 !important;
    border-radius: 100px;
    overflow: hidden;
}

.audio-box-sec .audio-box-menu .nav-item .nav-link {
    background-color: #000;
    color: #737373;
    font-size: 14px;
    font-family: var(--poppins);
    padding: 12px 0;
    font-weight: 500;
}

.horigental-line {
    border: 1px solid #BEBEBE;
    width: 100%;
    display: block;
}

.audio-music-box .card {
    background: #293758;
    border-radius: 10px;
    transition: .4s ease-in-out;
    overflow: hidden;
}

.audio-music-box .col-9 {
    overflow: hidden;
}

.audio-music-box .card:hover {
    transform: scale(1.05);
    transition: .4s ease-in-out;
}

.audio-music-box .card .card-footer {
    background-color: #6694E3;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}


.audio-music-box .card .card-title {
    padding: 10px;
    font-size: 12px;
}

.audio-music-box .card-body {
    padding: 0px;
}

.card-music-name h6 {
    font-size: 9px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.card-play-icon .fa-2x {
    font-size: 1.5em;
}

.audio-slide-box .form-group select {
    background-color: #394768;
    color: #fff;
    box-shadow: none;
    border-color: #707070;
    font-size: 14px;
}

.audio-language-box .form-group select {
    background-color: #394768;
    color: #fff;
    box-shadow: none;
    border-color: #707070;
    font-size: 14px;
}

.audio-language-box .form-group label {
    font-size: 12px;
    font-family: var(--poppins);
}

.audio-voice-box .form-group label {
    font-size: 12px;
    font-family: var(--poppins);
}

.audio-slide-box .form-group label {
    font-size: 12px;
    font-family: var(--poppins);
}

.audio-language-box .form-group .form-select {
    padding-right: 0px;
}

.audio-voice-box .form-group select {
    background-color: #394768;
    color: #fff;
    box-shadow: none;
    border-color: #707070;
    font-size: 14px;
}

.audio-voice-box .form-group .form-select {
    padding-right: 0px;
}

.audio-add-text-box textarea {
    background: #394768;
    border-color: #707070;
    font-size: 14px;
}

.audio-add-text-box textarea:focus {
    background: #0C0C0C;
    box-shadow: none;
}

.audio-add-text-box textarea.form-control {
    color: #787878;
}

.tts-box-wrapper {
    background: #16213B;
    border-radius: 10px;
    padding: 10px;
}

.tts-box-wrapper label {
    color: #737373;
}

.audio-preview-btn button {
    background: #ffffff;
    background: -moz-linear-gradient(left, #ffffff 0%, #ffffff 50%, #10ca9c 50%, #a72ce3 100%);
    background: -webkit-linear-gradient(left, #ffffff 0%, #ffffff 50%, #10ca9c 50%, #a72ce3 100%);
    background: linear-gradient(to right, #ffffff 0%, #ffffff 50%, #10ca9c 50%, #a72ce3 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#a72ce3', GradientType=1);
    background-size: 202%;
    background-position: 99% 50%;
    border: none;
    color: #fff;
    border-radius: 100px;
    height: 45px;
    transition: .3s ease-in-out;
}

.btn-change6:hover {
    background-position: 0% !important;
    color: #2fcac9 !important;
}

.btn-change6:focus {
    color: #fff;
}

.audio-save-use-btn button {
    background: #ffffff;
    background: -moz-linear-gradient(left, #ffffff 0%, #ffffff 50%, #00ffba 50%, #3b91cb 100%);
    background: -webkit-linear-gradient(left, #ffffff 0%, #ffffff 50%, #00ffba 50%, #3b91cb 100%);
    background: linear-gradient(to right, #ffffff 0%, #ffffff 50%, #00ffba 50%, #3b91cb 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#3b91cb', GradientType=1);
    background-size: 202%;
    background-position: 99% 50%;
    border: none;
    color: #fff;
    border-radius: 100px;
    height: 45px;
    transition: .3s ease-in-out;
}

.audio-save-use-btn button:hover {
    color: #2fcac9;
}



.audio-library-boxs .audio-upload-btn button {
    border-radius: 5px;
    background: #4ac6ff;
    background: -moz-linear-gradient(left, #4ac6ff 0%, #b376ff 100%);
    background: -webkit-linear-gradient(left, #4ac6ff 0%, #b376ff 100%);
    background: linear-gradient(to right, #4ac6ff 0%, #b376ff 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4ac6ff', endColorstr='#b376ff', GradientType=1);
    transition: .5s ease-in-out all;
    border: none;
    height: 45px;
    color: #fff;
}

.audio-upload-btn {
    position: relative;
}

.audio-library-boxs .audio-upload-btn .custom-file-input {
    position: absolute;
    z-index: 2;
    width: 100%;
    margin: 0;
    opacity: 0;
    background: red;
    border-radius: 30px;
    font-size: 1px;
    height: 45px;
    cursor: pointer;
}

.audio-library-boxs .audio-upload-btn .custom-file {
    position: absolute;
    display: inline-block;
    width: 100%;
    height: calc(2.25rem + 2px);
    margin-bottom: 0;
    top: 0;
    height: 100%;
}

.upload-music-box .card {
    background: linear-gradient(to bottom, #B94EFF, #DB438F);
    border: none;
    border-radius: 10px;
    transition: .4s ease-in-out;
}

.upload-music-box .card:hover {
    transform: scale(1.05);
    transition: .4s ease-in-out;
}

.upload-music-box .card-footer {
    background: #B240E6;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.upload-music-box .card-footer .col-9 {
    padding: 0 5px;
}

.upload-music-box .card-title {
    padding: 10px;
    font-size: 12px;
}

.upload-music-box .card-body {
    padding: 0px;
}

.card-play-icon {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.remove-logo-switch-btn .form-check-input:checked {
    background: #C23636;
    box-shadow: none;
    border-color: #C23636;
}

.remove-logo-switch-btn .form-switch .form-check-input:checked {
    background-position: calc(100% - 10%) calc(90% - 1px) !important;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
    background-repeat: no-repeat !important;
    box-shadow: none;
}


.remove-logo-switch-btn .form-check-input:checked[type=checkbox] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
    box-shadow: none;
    background-position: calc(100% - 10%) calc(90% - 0px) !important;
}

/* Aspect Ratio Start */
.preview-wrapper {
    height: 100%;
    position: relative;
}

.ar-16-9 {
    max-width: 100%;
    max-height: 100%;
}

.ar-9-16 {
    max-width: 30%;
    max-height: 100%;
}

.ar-1-1 {
    max-width: 45%;
    max-height: 80.1%;
}

.ar-4-3 {
    max-width: 90%;
    max-height: 100%;
}

.ar-3-4 {
    max-width: 50%;
    max-height: 100%;
    margin: 0 auto;
}

.slider-16-9 {
    width: 150px !important;
    height: 70px !important;
    margin: 0 auto !important;
}

.slider-9-16 {
    width: 50px !important;
    height: 70px !important;
    margin: 0 auto !important;
}

.slider-1-1 {
    width: 70px !important;
    height: 70px !important;
    margin: 0 auto !important;
}

.slider-3-4 {
    width: 60px !important;
    height: 70px !important;
    margin: 0 auto !important;
}

.slider-4-3 {
    width: 90px !important;
    height: 70px !important;
    margin: 0 auto !important;
}

.form-control:focus {
    box-shadow: 0 0 0 0;
}

.card-play-icon svg {
    color: #fff;
}

/* .btn-update{
    background: #3686ff;
    background: linear-gradient(90deg, #3686ff 0%, #c867ff 100%);
}
.btn-update:active{
background: #3686ff !important;
}
.btn-update:hover{
background: #3686ff;
background: linear-gradient(90deg, #3686ff 0%, #3686ff 100%);
} */
/* .search-videos label{
    padding: 10px;
    background: red; 
    display: table;
    color: #fff;
}

.search-videos input[type="file"] {
    display: none;
} */

/* .custom-file-input::-webkit-file-upload-button {
    visibility: hidden;
  }
  .custom-file-input::before {
    content: 'Select some files';
    display: inline-block;
    background: linear-gradient(top, #f9f9f9, #e3e3e3);
    border: 1px solid #999;
    border-radius: 3px;
    padding: 5px 8px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    text-shadow: 1px 1px #fff;
    font-weight: 700;
    font-size: 10pt;
  }
  .custom-file-input:hover::before {
    border-color: black;
  }
  .custom-file-input:active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
  } */

/* Choose File Csss */

label.search-video {
    padding: 10px 15px;
    background: #5a68c1;
    display: table;
    color: #fff;
    margin: 0;
    border-radius: 5px;
    text-transform: uppercase;
    position: relative;
}

label.search-video input[type="file"] {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}


.sketch-picker label {
    color: #000 !important;
}

.sketch-picker input {
    color: black !important;
}

input[type="file"] {
    display: block;
}

/* editor tabbbbb */
.card-play-icon svg {
    width: 10px !important;
}

/* dashboard tabbb */
.dashb-sec {
    position: sticky;
}

form.gdpr-update-email small {
    margin-bottom: 5px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
}

span.icon-response-span {
    margin: 0px 5px;
}

span.icon-response-span img {
    width: 55px;
    height: 55px;
}

form.gdpr-update-email input {
    background: #1e1e1e;
    border-radius: 10px;
    color: #fff;
}

.gdpr-update-email textarea {
    background: #1e1e1e;
    border-radius: 10px;
    color: #fff;
}


form.gdpr-update-email input::-webkit-input-placeholder {
    /* Edge */
    color: #fff;
}

.gdpr-update-email input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #fff;
}

.gdpr-update-email input::placeholder {
    color: #fff;
}

.gdpr-update-email textarea::-webkit-input-placeholder {
    /* Edge */
    color: #fff;
}

.gdpr-update-email textarea:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #fff;
}

.gdpr-update-email textarea::placeholder {
    color: #fff;
}

.gdpr-update-email select {
    background: #1e1e1e;
    /* inline SVG */
    /* background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20256%20448%22%20enable-background%3D%22new%200%200%20256%20448%22%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E.arrow%7Bfill%3A%23424242%3B%7D%3C%2Fstyle%3E%3Cpath%20class%3D%22arrow%22%20d%3D%22M255.9%20168c0-4.2-1.6-7.9-4.8-11.2-3.2-3.2-6.9-4.8-11.2-4.8H16c-4.2%200-7.9%201.6-11.2%204.8S0%20163.8%200%20168c0%204.4%201.6%208.2%204.8%2011.4l112%20112c3.1%203.1%206.8%204.6%2011.2%204.6%204.4%200%208.2-1.5%2011.4-4.6l112-112c3-3.2%204.5-7%204.5-11.4z%22%2F%3E%3C%2Fsvg%3E%0A"); */
    background-image: url("../../../images/arrow-down.png");
    background-position: right 10px center;
    background-repeat: no-repeat;
    background-size: auto 50%;
    border-radius: 2px;
    border: none;
    color: #ffffff;
    padding: 6px 30px 10px 10px;
    border-radius: 10px;
    color: #fff;
    outline: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    /* &::-ms-expand { display: none }; */
}

.gdpr-update-email select option {
    background: #1e1e1e;
}

.gdpr-update-email select option:focus,
.gdpr-update-email select option:focus {
    background: #1e1e1e;
}

.gdpr-update-email select::-webkit-input-placeholder {
    /* Edge */
    color: #fff;
}

.gdpr-update-email select:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #fff;
}

.gdpr-update-email select::placeholder {
    color: #fff;
}


.gdpr-update-email input:focus,
.gdpr-update-email select:focus,
form.gdpr-update-email textarea:focus {
    background: #272424;
    border-radius: 10px;
    color: #fff;
}

#scrollableEmoji .infinite-scroll-component.row {
    margin-left: -5px;
    margin-right: -5px;
}

#scrollableEmoji .infinite-scroll-component.row .col-4 {
    padding-left: 5px;
    padding-right: 5px;
}

#scrollableEmoji .infinite-scroll-component.row .colmn {
    margin-bottom: 10px;
}

.modal-body-scroll {
    max-height: 500px;
    overflow-x: hidden;
    overflow-y: auto;
}

.modal .wrap-intro h6 {
    padding-bottom: 15px;
}

.modal .demo-vid-wrapper {
    padding-right: 30px;
}

.modal button.form-control {
    background: #ffffff;
    background: -moz-linear-gradient(left, #ffffff 0%, #ffffff 50%, #a72ce3 50%, #cb3b71 100%);
    background: -webkit-linear-gradient(left, #ffffff 0%, #ffffff 50%, #a72ce3 50%, #cb3b71 100%);
    background: linear-gradient(to right, #ffffff 0%, #ffffff 50%, #a72ce3 50%, #cb3b71 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#cb3b71', GradientType=1);
    background-size: 202%;
    background-position: 99% 50%;
    transition: .3s ease-in-out;
    padding: 7px 25px;
    border-radius: 5px;
    border: none;
    color: #fff;
}

.modal .wrap-intro {
    padding-bottom: 15px;
}


.slide-box.inOut ul {
    margin: 0 !important;
    padding: 0 !important;
}

.slide-box.inOut ul.introThumb li {
    display: inline-block;
}

.slide-box.inOut ul.outroThumb li {
    display: inline-block;
}

.slide-box.inOut .row {
    flex-wrap: nowrap;
    margin: 0;
}

.slide-box.inOut .row .col {
    padding: 0;
}

.bg-ul.min {
    min-width: 1000px;
}

.slide-box ul.outroThumb li {
    margin-right: 0;
    margin-left: 20px;
}

.slide-box.inOut ul li img {
    width: 100%;
    overflow: hidden;
    object-fit: cover;
    object-position: center;
}

.mp4Al {
    display: inline-block;
    padding-left: 20px;
    color: red;
    font-style: italic;
}


@media (max-width: 1599px) {
    .modal-dialog-video-tutorial {
        max-width: 850px !important;
    }
}