.privacy-page-main {
  background: #000E34;
}

.privacy-page-main footer {
  position: unset;
}

.privacy-account {
  padding: 150px 0 100px;
}

.change-email-sec {
  margin-bottom: 40px;
}

.change-email-sec .form-wrapper,
.account-data-sec .account-data-box {
  background: #002D5A;
  /* background: #1d3166; */
  border-radius: 32px;
  box-shadow: 0px 0px 30px rgb(0 0 0 / 37%);
}

.change-email-sec .form-wrapper {
  /* padding: 60px 45px; */
  padding: 40px;
}

.account-data-sec .account-data-box {
  padding: 40px;
}

.change-email-sec .content-wrapper,
.account-data-sec .content-wrapper {
  max-width: 1435px;
}

.change-email-sec .form-group input {
  width: 100%;
  background: transparent;
  border: 0;
  outline: none;
}

.change-email-sec .form-wrapper .form-group {
  background: #1B344E;
  border: 1px solid #D9D9D9;
  border-radius: 8px;
  padding: 10px 20px;
  flex: 0 0 50%;
  max-width: 49%;
}

.change-email-sec .change-email-submit {
  width: 100%;
  background: #3686ff;
  background: -moz-linear-gradient(-45deg,  #3686ff 0%, #c867ff 50%, #c867ff 50%, #3686ff 100%);
  background: -webkit-linear-gradient(-45deg,  #3686ff 0%,#c867ff 50%,#c867ff 50%,#3686ff 100%);
  background: linear-gradient(135deg,  #3686ff 0%,#c867ff 50%,#c867ff 50%,#3686ff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3686ff', endColorstr='#3686ff',GradientType=1 );  
  background-size: 200% auto;
  margin-top: 45px;
  padding: 20px 0;
  border-radius: 100px;
}
.change-email-sec .change-email-submit:hover {
  background-position: right;
}
.create-acc-submit{
  width: 100%;
  background: #3686ff;
  background: -moz-linear-gradient(-45deg,  #3686ff 0%, #c867ff 50%, #c867ff 50%, #3686ff 100%);
  background: -webkit-linear-gradient(-45deg,  #3686ff 0%,#c867ff 50%,#c867ff 50%,#3686ff 100%);
  background: linear-gradient(135deg,  #3686ff 0%,#c867ff 50%,#c867ff 50%,#3686ff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3686ff', endColorstr='#3686ff',GradientType=1 );  
  background-size: 200% auto;
  margin-top: 45px;
  padding: 20px 0;
  border-radius: 100px;
}
.create-acc-submit:hover {
  background-position: right;
}

.create-acc-submit{
  width: 100%;
  background: #3686ff;
  background: -moz-linear-gradient(-45deg,  #3686ff 0%, #c867ff 50%, #c867ff 50%, #3686ff 100%);
  background: -webkit-linear-gradient(-45deg,  #3686ff 0%,#c867ff 50%,#c867ff 50%,#3686ff 100%);
  background: linear-gradient(135deg,  #3686ff 0%,#c867ff 50%,#c867ff 50%,#3686ff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3686ff', endColorstr='#3686ff',GradientType=1 );  
  background-size: 200% auto;
  margin-top: 45px;
  padding: 20px 0;
  border-radius: 100px;
}
.create-acc-submit:hover {
  background-position: right;
}


.edit-user .btn-update{
  width: 100%;
  background: #3686ff;
  background: -moz-linear-gradient(-45deg,  #3686ff 0%, #c867ff 50%, #c867ff 50%, #3686ff 100%);
  background: -webkit-linear-gradient(-45deg,  #3686ff 0%,#c867ff 50%,#c867ff 50%,#3686ff 100%);
  background: linear-gradient(135deg,  #3686ff 0%,#c867ff 50%,#c867ff 50%,#3686ff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3686ff', endColorstr='#3686ff',GradientType=1 );  
  background-size: 200% auto;
  margin-top: 45px;
  padding: 20px 0;
  border-radius: 100px;
}

.edit-user .btn-update:hover{
  background-position: right;
}

.account-data-box .row {
  padding: 25px;
  background: #1B344E;
  border-radius: 32px;
}

.account-data-box .row:not(:last-child) {
  margin-bottom: 30px;
}

.account-data-btn {
  padding: 10px 15px;
  border-radius: 100px;
  border: none;
}

.account-data-box .text-wrapper {
  flex: 0 0 65%;
  max-width: 65%;
}

.account-data-box .btn-wrapper {
  flex: 0 0 35%;
  max-width: 35%;
  display: flex;
  justify-content: end;
}

.account-data-btn {
  text-transform: uppercase;
}

.account-data-btn.download-data {
  background: #2EA2CE;
}

.account-data-btn.stop-data {
  background: #DF8B4B;
}

.account-data-btn.delete-data {
  background: #D83C47;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: unset;
  }
}

@media screen and (max-width: 1680.98px) { 

}

@media screen and (max-width: 1500.98px) {	
}

@media screen and (max-width: 1440.98px) { 

}

@media screen and (max-width: 1366.98px) {

}

@media screen and (max-width: 1280.98px) {

}

@media screen and (max-width: 1199.98px) {
  .privacy-page-main h6, 
  .privacy-page-main .md-txt {
    font-size: 16px;
  }

  .change-email-sec .change-email-submit {
    margin-top: 25px;
  }

  .change-email-sec .content-wrapper, .account-data-sec .content-wrapper {
    max-width: 100%;
  }

  .privacy-account .custom-wrapper {
    max-width: 90%;
  }
}

@media screen and (max-width: 1024.98px) {
  .account-data-box .text-wrapper {
    flex: 0 0 55%;
    max-width: 55%;
  }

  .account-data-box .btn-wrapper {
    flex: 0 0 45%;
    max-width: 45%;
  }

  .account-data-box .row {
    padding: 35px 30px;
  }
}

@media screen and (max-width: 991.98px) {
  .privacy-account {
    padding: 120px 0 50px;
  }
}

@media screen and (max-width: 832.98px) {  
  .privacy-account .custom-wrapper {
    max-width: 95%;
  }
}

@media screen and (max-width: 767.98px) {

}

@media screen and (max-width: 640.98px) {
  .change-email-sec .form-wrapper .form-group {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .change-email-sec .form-wrapper .form-group.colmn-1 {
    margin-bottom: 20px!important;
  }

  .account-data-box .text-wrapper {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 20px;
  }

  .account-data-box .btn-wrapper {
    flex: 0 0 100%;
    max-width: 100%;
    justify-content: center;
  }
}

@media screen and (max-width: 575.98px) {

}

@media screen and (max-width: 440.98px) {
  .privacy-account h4, 
  .privacy-account .h4 {
    font-size: 18px;
  }

  .change-email-sec .form-wrapper {
    padding: 30px 25px;
    border-radius: 20px;
  }

  .change-email-sec .form-wrapper .form-group {
    padding: 18px 15px;
  }

  .change-email-sec .change-email-submit {
    padding: 15px 0;
  }

  .account-data-sec .account-data-box {
    padding: 20px;
  }

  .privacy-page-main h6, .privacy-page-main .md-txt {
    font-size: 14px;
  }
}

@media screen and (max-width: 360.98px) { 
  .privacy-account {
    padding: 100px 0 30px;
  }

  .account-data-box .row {
    padding: 35px 20px;
  }
}