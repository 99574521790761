.dashboard-page-main .footer {
  position: unset;
  background: #00113E;
} 

.banner {
  padding: 220px 0 160px;
  /*background: url(../images/banner-bg.png);*/
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
}

.banner .content-wrapper {
  max-width: 1300px;
}

.banner .demo-btn {
  background: rgb(203,0,196);
  background: -moz-linear-gradient(45deg,  rgba(203,0,196,1) 0%, rgba(110,0,255,1) 100%);
  background: -webkit-linear-gradient(45deg,  rgba(203,0,196,1) 0%,rgba(110,0,255,1) 100%);
  background: linear-gradient(45deg,  rgba(203,0,196,1) 0%,rgba(110,0,255,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cb00c4', endColorstr='#6e00ff',GradientType=1 );
  max-width: max-content;
  padding: 18px 30px;
  border-radius: 100px;
  border: 3px solid #fff;
}

.banner .colmn-1 .text-wrapper {
  margin: 20px 0;
  max-width: 90%;
}

.banner .demo-btn:hover .play-icon {
  margin-right: 20px;
}

.banner .demo-btn .play-icon {
  margin-right: 10px;
  transition: .3s ease-in-out all;
}

.banner .in-wrap-head {
  background: #051738;
  padding: 15px;
  border-radius: 100px 0 0 100px;
  border: 4px solid #9C53F3;
  border-right: none;
  transition: .3s ease-in-out all;
}

.banner .in-wrap-btn svg {
  width: 25px;
}

.banner .in-wrap-btn {
  background: #9C53F3;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  border-radius: 0 15px 15px 0;
}

.banner .colmn-2 .wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.banner .colmn-2 .in-wrap {
  flex: 0 0 50%;
  max-width: 48%;
  margin-bottom: 20px;
  justify-content: end;
}

.banner .in-wrap:hover .in-wrap-head {
  padding: 15px !important;
  /* padding-right: 35px; */
}

.banner .in-wrap-head .img-wrapper {
  width: 40px;
}

/* Section 1 Start */

.s1 {
  background: #000E34;
  padding: 20px 0 30px;
}

.s1 .custom-wrapper {
  max-width: 1730px;
  /* min-height: 92vh; */
}

.s1 .custom-wrapper-pro { min-height: calc(100vh - 184px);}

.s1 .proj-btn {
  background: rgb(203,0,196);
  background: -moz-linear-gradient(45deg,  rgba(203,0,196,1) 0%, rgba(110,0,255,1) 100%);
  background: -webkit-linear-gradient(45deg,  rgba(203,0,196,1) 0%,rgba(110,0,255,1) 100%);
  background: linear-gradient(45deg,  rgba(203,0,196,1) 0%,rgba(110,0,255,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cb00c4', endColorstr='#6e00ff',GradientType=1 );
  padding: 10px 25px;
  border-radius: 100px;
}

.s1 .proj-btn:hover p {
  margin-right: 30px;
}

.s1 .proj-btn p {
  margin-right: 16px;
}

.s1 .title-area {
  margin-bottom: 60px;
}

.s1 .projects .img-wrapper {
  height: 260px;
  overflow: hidden;
  width: 100%;
  border-radius: 14px;
}

.s1 .projects .img-wrapper img {
  object-fit: cover;
  object-position: 50% 50%;
  height: 100%;
}

.s1 .projects .demo-play-icon {
  height: 44px;
  width: 44px;
  background: #004A9E;
  border-radius: 100px;
  margin-right: 10px;
  flex: 0 0 44px;
}

.s1 .projects .title-area {
  background: #005AD0;
  padding: 0px 24px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  width: 100%;
}

.s1 .projects .colmn:not(:nth-child(4n + 4)) {
  margin-right: 1.3%;
}

.s1 .projects .colmn {
  padding: 13px 13px 30px 12px;
  background: #0077FF;
  border-radius: 20px;
  flex: 0 0 25%;
  max-width: 24%;
  margin-bottom: 25px;
}


.s1 .proj-info {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: rgba(0 0 0 / 70%);
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: .3s ease-in-out all;
}

.s1 .projects .img-wrapper:hover .proj-info {
  opacity: 1;
}

.s1 .proj-info .row.flex-elem {
  max-width: 90%;
}

.s1 .proj-info .cursor-pointer {
  cursor: pointer;
  display: block;
}

.s1 .proj-info .cursor-pointer:hover svg {
  color: #00c87e;
}

.s1 .proj-info .icon {
  width: 40px;
}

.s1 .proj-info .icon svg {
  color: #333;
}

.s1 .proj-info .icon {
  width: 40px;
  height: 40px;
  background: #fff;
  border-radius: 100px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

/* Section 2 Start */
.s2 {
  padding: 70px 0 130px;
  background: rgb(0,14,52);
  background: -moz-linear-gradient(top,  rgba(0,14,52,1) 0%, rgba(7,23,64,1) 30%, rgba(46,67,124,1) 55%, rgba(73,97,164,1) 100%);
  background: -webkit-linear-gradient(top,  rgba(0,14,52,1) 0%,rgba(7,23,64,1) 30%,rgba(46,67,124,1) 55%,rgba(73,97,164,1) 100%);
  background: linear-gradient(to bottom,  rgba(0,14,52,1) 0%,rgba(7,23,64,1) 30%,rgba(46,67,124,1) 55%,rgba(73,97,164,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000e34', endColorstr='#4961a4',GradientType=0 );
}

.s2 .content-wrapper {
  max-width: 1366px;
}

.s2 .row:not(:last-child) {
  margin-bottom: 100px;
}

.s2 .colmn .title-area .h3 {
  color: #00FFFF;
}

.s2 .colmn-1 {
  padding-right: 120px;
}

/* Section 3 Start */
.s3 {
  padding: 120px 0;
  /*background: url(../images/s3-bg.png);*/
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.s3 .content-wrapper {
  max-width: 1490px;
}

.s3 .demo-btn {
  background: rgb(203,0,196);
  background: -moz-linear-gradient(45deg,  rgba(203,0,196,1) 0%, rgba(110,0,255,1) 100%);
  background: -webkit-linear-gradient(45deg,  rgba(203,0,196,1) 0%,rgba(110,0,255,1) 100%);
  background: linear-gradient(45deg,  rgba(203,0,196,1) 0%,rgba(110,0,255,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cb00c4', endColorstr='#6e00ff',GradientType=1 );
  max-width: max-content;
  padding: 18px 30px;
  border-radius: 100px;
  border: 3px solid #fff;
}

.s3 .demo-btn.demo-btn-alt{ padding: 10px 60px; margin-top: 30px;}
.s3 .demo-btn.demo-btn-alt p.h5.f-500{ transform: translateY(-3px);}

.s3 .demo-btn:hover .play-icon {
  margin-right: 20px;
}

.s3 .demo-btn .play-icon {
  margin-right: 10px;
  transition: .3s ease-in-out all;
}

.demo-vid-wrapper {
  width: 1000px;
}

.demo-modal .modal-content {
  background: #0A1224!important;
  /* background: none !important; */
  /* background: #0091c8;
  background: -moz-linear-gradient(-45deg,  #0091c8 0%, #00cb7a 100%);
  background: -webkit-linear-gradient(-45deg,  #0091c8 0%,#00cb7a 100%);
  background: linear-gradient(135deg,  #0091c8 0%,#00cb7a 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0091c8', endColorstr='#00cb7a',GradientType=1 ); */
}

.demo-modal .modal-header {
  border: none;
}

.publish-video-modal .modal-content{ position: relative;}
.modal-content-loader{ position: absolute; width: 100%; height: 100%; left: 0; top: 0; background: rgba(0,0,0,0.8); z-index: 1; display: flex; align-items: center; justify-content: center; padding: 4px 0 0 0; border-radius: 100px;}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  transform: scale(0.5);
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}


@media (min-width: 576px) {
  .modal-dialog {
    max-width: unset;
  }
}

@media screen and (max-width: 1680.98px) { 
  .s1 .custom-wrapper {
    max-width: 98%;
  }

}

@media screen and (max-width: 1500.98px) {	
  .s2 .custom-wrapper {
    max-width: 90%;
  }

  .s3 .custom-wrapper {
    max-width: 90%;
  }
}

@media screen and (max-width: 1440.98px) { 
  .s1 .projects .img-wrapper {
    height: 220px;
  }
}

@media screen and (max-width: 1366.98px) {
  h3, .h3 {
    font-size: 26px;
  }

  h4, .h4 {
    font-size: 22px;
  }

  .s2 {
    padding: 40px 0 70px;
  }

  .banner .content-wrapper {
    max-width: 1130px;
  }

  .banner .fs-18 {
    font-size: 14px;
  }

  .s1 .proj-info .row.flex-elem {
    max-width: 100%;
  }
}

@media screen and (max-width: 1280.98px) {
  h1, .h1 {
		font-size: 70px;
	}

  h2, .h2 {
    font-size: 35px;
  }

  .banner {
    padding: 200px 0 100px;
  }

  .banner .content-wrapper {
    max-width: 90%;
  }

  .s1 .proj-info {
    padding: 20px 0px;
  }

  .s1 .fs-18 {
    font-size: 14px;
  }
}

@media screen and (max-width: 1199.98px) {
  h6, .md-txt {
    font-size: 16px;
  }
  
  .s1 .projects .img-wrapper {
    height: 160px;
  }

  .s1 .projects .colmn {
    padding: 13px 13px 20px 12px;
    border-radius: 10px;
  }

  .s2 .colmn-1 {
    padding-right: 15px;
  }
}

@media screen and (max-width: 1024.98px) {
  .banner .content-wrapper {
    max-width: 75%;
  }

  .banner .colmn {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .banner .colmn-2 .wrapper {
    max-width: 78%;
    margin: 50px auto 0;
  }

  .demo-vid-wrapper {
    width: 700px;
  }
}

@media screen and (max-width: 991.98px) {
  .banner {
    padding: 160px 0 100px;
  }

  .s1 .projects .colmn { 
    flex: 0 0 33%;
    max-width: 31%;
    margin-bottom: 30px;
  }

  .s1 .projects .colmn:not(:nth-child(3n + 3)) {
    margin-right: 2.8%;
  }
}

@media screen and (max-width: 832.98px) {  
  .fs-18 {
    font-size: 12px;
  }

  .banner .colmn {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .banner .colmn-2 .wrapper {
    align-items: center;
  }

  .banner .content-wrapper {
    max-width: 80%;
  }

  .banner .colmn-2 .wrapper {
    max-width: 90%;
    margin: 10px auto 0;
  }

  .s1 {
    padding: 20px 0 30px;
  }

  .s1 .projects .title-area {
    padding: 0px 15px;
  }

  .s1 .projects .colmn {
    margin-bottom: 25px;
  }
  

  .s3 {
    padding: 70px 0 30px;
  }

  .s1 .proj-info .icon {
    width: 35px;
    height: 35px;
  }

  .s1 .projects .fs-12 {
    font-size: 10px;
  }
}

@media screen and (max-width: 767.98px) {
  h3, .h3 {
    font-size: 27px;
  }

  .md-txt{
    font-size: 18px;
  }

  .demo-vid-wrapper {
    width: 600px;
  }

  .banner .colmn-2 .wrapper {
    max-width: 100%;
  }
}

@media screen and (max-width: 640.98px) {
  h1, .h1 {
		font-size: 55px;
	}

	h2, .h2 {
		font-size: 28px;
	}

  h3, .h3 {
    font-size: 25px;
  }

  h4, .h4 {
    font-size: 16px;
  }

  .banner {
    padding: 130px 0 70px;
  }

  .banner .content-wrapper {
    max-width: 80%;
  }

  .banner .colmn {
    text-align: center;
  }

  .banner .demo-btn {
    margin: 0 auto;
  }

  .banner .in-wrap-head .img-wrapper {
    width: 25px;
  }

  .banner p.h4 {
    line-height: 1.8;
  }

  .s1 .projects {
    justify-content: space-between;
  }

  .s1 .projects .colmn {
    flex: 0 0 50%;
    max-width: 48%;
    margin-right: unset!important;
  }

  .s1 .proj-btn {
    padding: 10px 20px;
  }

  .s1 .title-area {
    margin-bottom: 30px;
  }

  .demo-vid-wrapper {
    width: 500px;
  }
}

@media screen and (max-width: 575.98px) {
  .banner .content-wrapper {
    max-width: 90%;
  }

  .banner .colmn-1 .text-wrapper {
    margin: 20px auto;
  }
}

@media screen and (max-width: 440.98px) {
  .banner .content-wrapper {
    max-width: 95%;
  }

  .banner .in-wrap-head .img-wrapper {
    max-width: 50px;
  }

  .banner .in-wrap-head {
    padding: 15px 40px 15px 15px;
  }

  .banner .in-wrap-btn {
    padding: 0 25px;
  }

  .banner .colmn-2 .in-wrap {
    flex: 0 0 100%;
    max-width: 100%;
    justify-content: center;
  }

  .demo-vid-wrapper {
    width: 100%;
  }

  .banner .demo-btn .h4,
  .s3 .demo-btn .h4 {
    line-height: unset;
  }

  .s1 .projects .colmn {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .s1 .projects .img-wrapper {
    height: 220px;
  }

  .s1 .title-area {
    justify-content: center!important;
  }

  .s1 .proj-btn {
    margin-top: 20px;
  }
}

@media screen and (max-width: 360.98px) { 
  .fs-15 {
    font-size: 12px;
  }

  h1, .h1 {
    font-size: 45px;
  }

  h3, .h3 {
    font-size: 20px;
  }

  .md-txt {
    font-size: 14px;
  }
}